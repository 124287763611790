import { createContext, useContext, useEffect, useState } from 'react';

import { ThemeMode } from '../shared/interfaces/bot';
import { AuthContext } from './auth';

export interface Theme {
  mode: ThemeMode;
  setMode?(mode: ThemeMode): void;
}

const defaultState: Theme = {
  mode: ThemeMode.Light,
};

export const ThemeContext = createContext<Theme>(defaultState);

export function ThemeContextProvider({ children }: any) {
  const { currentUser } = useContext(AuthContext);
  const [themeMode, setThemeMode] = useState<ThemeMode>(ThemeMode.Light);

  useEffect(() => {
    if (!currentUser) {
      return;
    }

    if (currentUser.preferences.theme) {
      setThemeMode(currentUser.preferences.theme);
    }
  }, [currentUser]);

  return <ThemeContext.Provider value={{ mode: themeMode, setMode: setThemeMode }}>{children}</ThemeContext.Provider>;
}
