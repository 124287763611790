import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import axios from '../../../shared/custom-axios';
import socket from '../../../socket';
import { Channel } from 'phoenix';
import { Classes, Dialog, Intent } from '@blueprintjs/core';

import { AppToaster } from '../../../shared/app-toaster';
import {
  ActiveOrder,
  Opportunity,
  OrderBooksListResponse,
  TradingPair,
  TradingPairResponse,
  WickCatcher,
} from '../../../shared/interfaces/bot';
import { BotsManagerContext } from '../../../contexts/bots-manager';
import { TimezoneContext } from '../../../contexts/timezone';

import { WickCatcherEditDialog } from '../edit-dialog/edit-dialog';
import orderService from '../../../shared/order-service';
import { ActiveOrderContext } from '../../../contexts/active-order';
import { TradingPairEditor } from '../../trading-pair/editor/editor';
import styles from './list.module.css';
import { WickCatcherFundingJobs } from '../funding-job/funding-job';
import { WickCatchersListItem } from './item/item';

interface WickCatcherResponse {
  data: WickCatcher;
}

interface Props {
  items: WickCatcher[];
  setItems: (items: any) => void;
  editingItem: WickCatcher | null;
  setEditingItem: (item: WickCatcher | null) => void;
  layoutColumns: number;
}

export function WickCatchersList({ items, setItems, editingItem, setEditingItem, layoutColumns }: Props) {
  const { currentBot } = useContext(BotsManagerContext);
  const { activeOrders } = useContext(ActiveOrderContext);
  const { dateTimeFormater } = useContext(TimezoneContext);
  const [selectedItem, setSelectedItem] = useState<WickCatcher | null>(null);
  const [lastSelectedItem, setLastSelectedItem] = useState<WickCatcher | null>(null);
  // const [editingItem, setEditingItem] = useState<WickCatcher | null>(null);
  const [processingPairs, setProcessingPairs] = useState<Set<string>>(new Set());

  const [selectedPair, setSelectedPair] = useState<TradingPair | null>(null);
  const [selectedPairForFunding, setSelectedPairForFunding] = useState<TradingPair | null>(null);

  let wickCatcherChannel = useRef<Channel | null>(null);
  let tradingPairChannel = useRef<Channel | null>(null);

  // Sort the list by creation time (oldest first)
  items = items.map((item) => {
    let pairs = _.sortBy(item.pairs, (pair) => pair.id);

    return { ...item, pairs: pairs };
  });

  useEffect(() => {
    wickCatcherChannel.current = socket.channel(`wick_catcher:lobby`, { from: 'WickCatchersList' });

    wickCatcherChannel.current
      ?.join()
      .receive('ok', () => {
        console.log(`[WickCatchersList] Joined "wick_catcher:lobby" channel for wick catchers real-time updates`);
      })
      .receive('error', (resp: any) => {
        console.log(`[WickCatchersList] Cannot join "wick_catcher:lobby" channel for wick catchers real-time updates`, resp);
      });

    // Reload data with updates from WebSocket
    wickCatcherChannel.current?.on('wick_catcher:update', ({ data }) => {
      data.id && handleReloadItem(data.id);
    });

    wickCatcherChannel.current?.onClose(() => {
      console.log(`[WickCatchersList] Left "wick_catcher:lobby" channel`);
    });

    return () => {
      wickCatcherChannel.current?.leave();
    };
  }, []);

  useEffect(() => {
    tradingPairChannel.current = socket.channel(`trading_pair:lobby`, { from: 'WickCatchersList' });

    tradingPairChannel.current
      ?.join()
      .receive('ok', () => {
        console.log(`[WickCatchersList] Joined "trading_pair:lobby" channel for trading pairs real-time updates`);
      })
      .receive('error', (resp: any) => {
        console.log(`[WickCatchersList] Cannot join "trading_pair:lobby" channel for trading pairs real-time updates`, resp);
      });

    // Reload data with updates from WebSocket
    tradingPairChannel.current?.on('trading_pair:update', ({ data }) => {
      data.id && handleReloadPairItem(data.id);
    });

    tradingPairChannel.current?.onClose(() => {
      console.log(`[WickCatchersList] Left "trading_pair:lobby" channel`);
    });

    return () => {
      console.log(`[WickCatchersList] Left "trading_pair:lobby" channel`);

      tradingPairChannel.current?.leave();
    };
  }, []);

  useEffect(() => {
    const mappings = activeOrders.reduce((acc: { [key: string]: Array<ActiveOrder> }, order: ActiveOrder) => {
      const currentOrders = acc[order.opportunity.id];

      if (currentOrders && currentOrders.length > 0) {
        acc[order.opportunity.id] = [...currentOrders, order];
      } else {
        acc[order.opportunity.id] = [order];
      }

      return acc;
    }, {});

    items.forEach((item: WickCatcher) => {
      const index = item.pairs.findIndex((pair: TradingPair) => {
        return (
          pair.opportunities.findIndex(
            (opp: Opportunity) =>
              mappings[opp.id] && mappings[opp.id].findIndex((order: ActiveOrder) => order.opportunity.percentage !== opp.percentage) >= 0,
          ) >= 0
        );
      });
      if (index >= 0) {
        console.log('Reloading wick catcher');
        handleReloadItem(item.id);
      }
    });
  }, [activeOrders]);

  const handleReloadItem = useCallback(
    (wickCatcherId: number) => {
      return axios
        .get<WickCatcherResponse>(`/api/wick_catchers/${wickCatcherId}`)
        .then((response) => {
          const newData = response.data.data;

          setItems((prevState: WickCatcher[]) => {
            return prevState.map((wickCatcher) => {
              if (wickCatcher.id === newData.id) {
                return { ...wickCatcher, ...newData };
              } else {
                return wickCatcher;
              }
            });
          });

          if (selectedItem && selectedItem.id === newData.id) {
            setSelectedItem({ ...selectedItem, ...newData });
          }
        })
        .catch((error) => {
          const message = JSON.stringify(error.response.data);
          AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
        });
    },
    [setItems, selectedItem],
  );

  const handleReloadPairItem = useCallback(
    (tradingPairId: number) => {
      return axios
        .get<TradingPairResponse>(`/api/trading_pairs/${tradingPairId}`)
        .then((response) => {
          const newData = response.data.data;

          setItems((prevState: WickCatcher[]) => {
            return prevState.map((wickCatcher) => {
              if (wickCatcher.id === newData.wick_catcher_id) {
                let index = wickCatcher.pairs.findIndex((item) => item.id === newData.id);

                if (index > -1) {
                  let newPairs = [...wickCatcher.pairs];
                  newPairs[index] = newData;

                  return { ...wickCatcher, pairs: newPairs };
                }

                return wickCatcher;
              } else {
                return wickCatcher;
              }
            });
          });
        })
        .catch((error) => {
          const message = JSON.stringify(error.response.data);
          AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
        });
    },
    [setItems],
  );

  const handleDelete = useCallback((wickCatcherId: number) => {
    return axios
      .delete<OrderBooksListResponse>(`/api/wick_catchers/${wickCatcherId}`)
      .then(() => {
        setItems((prevState: WickCatcher[]) => {
          const newState = prevState.filter(({ id }) => id !== wickCatcherId);
          return newState;
        });

        const message = 'Wick Catcher deleted successfully!';
        AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 2000 });
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      });
  }, []);

  const handleClone = useCallback((wickCatcherId: number) => {
    return axios
      .post<WickCatcherResponse>(`/api/wick_catchers/${wickCatcherId}/clone`)
      .then((response) => {
        setItems((prevState: WickCatcher[]) => {
          const newData = response.data.data;

          return [...prevState, newData];
        });

        const message = 'Wick Catcher duplicated successfully!';
        AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 2000 });
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      });
  }, []);

  const handleStart = useCallback((wickCatcherId: number, mode: object) => {
    return axios
      .put<WickCatcherResponse>(`/api/wick_catchers/${wickCatcherId}/start`, mode)
      .then((response) => {
        const newData = response.data.data;

        setItems((prevState: WickCatcher[]) => {
          return prevState.map((item) => {
            if (item.id === newData.id) {
              const updatedPairData = Object.assign({}, newData, {
                pairs: newData.pairs.map((p) => Object.assign({}, p, mode)),
              });
              return { ...item, ...updatedPairData };
            } else {
              return item;
            }
          });
        });

        if (selectedItem && selectedItem.id === newData.id) {
          setSelectedItem({ ...selectedItem, ...newData });
        }

        orderService.onUpdateActiveTradingPairs(wickCatcherId);

        const message = 'Wick Catcher started';
        AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 2000 });
      })
      .catch((error) => {
        const error_message = JSON.stringify(error.response.data);
        AppToaster.show({ message: error_message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      });
  }, []);

  const handleStop = useCallback((wickCatcherId: number, mode: object) => {
    return axios
      .put<WickCatcherResponse>(`/api/wick_catchers/${wickCatcherId}/stop`, mode)
      .then((response) => {
        const newData = response.data.data;

        setItems((prevState: WickCatcher[]) => {
          return prevState.map((item) => {
            if (item.id === newData.id) {
              return { ...item, ...newData };
            } else {
              return item;
            }
          });
        });

        if (selectedItem && selectedItem.id === newData.id) {
          setSelectedItem({ ...selectedItem, ...newData });
        }

        orderService.onUpdateActiveTradingPairs(wickCatcherId);

        const message = 'Wick Catcher stopped';
        AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 2000 });
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      });
  }, []);

  const handleOpen = useCallback((item: WickCatcher) => {
    setSelectedItem(item);
    setLastSelectedItem(item);
    window.history.replaceState({}, '', `/bots/${currentBot?.app_id}/wick_catchers/${item.id}`);
  }, []);

  const handleEdit = useCallback((item: WickCatcher) => {
    setEditingItem(item);
  }, []);

  const handleResume = useCallback((wickCatcherId: number) => {
    return axios
      .put<WickCatcherResponse>(`/api/wick_catchers/${wickCatcherId}/resume`)
      .then((response) => {
        const newData = response.data.data;

        setItems((prevState: WickCatcher[]) => {
          return prevState.map((item) => {
            if (item.id === newData.id) {
              return { ...item, ...newData };
            } else {
              return item;
            }
          });
        });

        if (selectedItem && selectedItem.id === newData.id) {
          setSelectedItem({ ...selectedItem, ...newData });
        }

        const message = `The wick catcher's pairs were manually resumed successfully`;
        AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 2000 });
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      });
  }, []);

  const handleCloseAction = useCallback((needToRefresh = false) => {
    setSelectedItem(null);
    if (needToRefresh) {
      window.history.replaceState({}, '', `/bots/${currentBot?.app_id}/wick_catchers`);
    }
  }, []);

  const handleOpenPair = useCallback((tradingPair: TradingPair) => {
    setSelectedPair(tradingPair);
  }, []);

  const handleClosePair = () => {
    setSelectedPair(null);
  };

  return (
    <>
      <div className={`wick-catchers grid gap-5 grid-cols-${layoutColumns} mt-5`}>
        {items?.map((item: WickCatcher, i: number) => {
          return (
            <WickCatchersListItem
              key={item.id}
              item={item}
              isLastSelectedItem={item === lastSelectedItem}
              onOpen={handleOpen}
              onEdit={handleEdit}
              onResume={handleResume}
              onClone={handleClone}
              onDelete={handleDelete}
              onReload={handleReloadItem}
              onStart={handleStart}
              onStop={handleStop}
              onCloseAction={handleCloseAction}
              onOpenPair={handleOpenPair}
              onReloadPair={handleReloadPairItem}
              onSelectPairForFunding={setSelectedPairForFunding}
            ></WickCatchersListItem>
          );
        })}

        <Dialog
          portalContainer={document.getElementById('app') || undefined}
          title={`#${selectedPair?.id} ${selectedPair?.name}`}
          transitionDuration={500}
          isOpen={!!selectedPair}
          onClose={() => {
            handleClosePair();
          }}
          className={styles.smallDialog}
        >
          {selectedPair && (
            <TradingPairEditor tradingPair={selectedPair} onStart={() => {}} onStop={() => {}} onDelete={() => {}} onReload={() => {}} />
          )}
        </Dialog>

        <Dialog
          portalContainer={document.getElementById('app') || undefined}
          title={`#${selectedPairForFunding?.id} Scheduled jobs`}
          transitionDuration={500}
          isOpen={!!selectedPairForFunding}
          onClose={() => {
            setSelectedPairForFunding(null);
          }}
          style={{ width: '700px' }}
        >
          <div className={Classes.DIALOG_BODY}>
            {selectedPairForFunding && <WickCatcherFundingJobs pair={selectedPairForFunding}></WickCatcherFundingJobs>}
          </div>
        </Dialog>

        <WickCatcherEditDialog item={editingItem} setItem={setEditingItem} onReloadItem={handleReloadItem} />
      </div>
    </>
  );
}
