import { FormEvent, useContext, useEffect, useMemo, useState } from 'react';
import { Button, Callout, Code, ControlGroup, FormGroup, H5, HTMLSelect, InputGroup } from '@blueprintjs/core';

import { ExchangesInfoContext, SubExchangeInfo } from '../../../contexts/exchanges-info';
import { Account, AccountType, ExchangeType } from '../../../shared/interfaces/bot';
import { supportedBlockChains } from '../../../shared/supported-blockchains';

const accountTypes = [
  { id: 'main', name: 'Main Account' },
  { id: 'sub', name: 'Sub-Account' },
];

const exchangeTypes = [
  { id: 'cex', name: 'Centralized Exchange (CEX)' },
  { id: 'dex', name: 'Decentralized Exchange (DEX)' },
];

interface AccountFormState {
  type: string;
  name: string;
  secret_identifier: string;
  exchange_type: string;
  main_exchange: string;
  blockchain: string;
  address: string;
  blur_max_fee_per_tx: string;
  blur_max_fee_per_hour: string;
}

interface Props {
  item?: Account | null;
  onCreate?: (data: any) => void;
  onUpdate?: (instrumentId: number, data: any) => void;
}

export function AccountForm({ item, onCreate, onUpdate }: Props) {
  const supportedExchanges = useContext(ExchangesInfoContext);

  const initForm: AccountFormState = useMemo(() => {
    if (item) {
      return {
        type: item.type,
        name: item.name,
        secret_identifier: item.secret_identifier,
        exchange_type: item.exchange_type,
        main_exchange: item.main_exchange,
        blockchain: item.blockchain,
        address: item.address,
        blur_max_fee_per_tx: String(item.blur_max_fee_per_tx),
        blur_max_fee_per_hour: String(item.blur_max_fee_per_hour),
      };
    }

    return {
      type: 'main',
      name: '',
      secret_identifier: '',
      exchange_type: '',
      main_exchange: '',
      blockchain: '',
      address: '',
      blur_max_fee_per_tx: '',
      blur_max_fee_per_hour: '',
    };
  }, [item]);

  const [formState, setFormState] = useState(initForm);
  const [, setSubExchanges] = useState<SubExchangeInfo[]>([]);

  const handleInputChange = (event: FormEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = event.currentTarget;

    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleIdentifierChange = (event: FormEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = event.currentTarget;

    setFormState((prevState) => ({
      ...prevState,
      [name]: value && value.toUpperCase(),
    }));
  };

  const handleMainExchangeChange = (event: FormEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = event.currentTarget;
    setFormState((prevState) => ({ ...prevState, [name]: value }));

    setSubExchanges(supportedExchanges.find((c) => c.id === value)!.sub_exchanges);
  };

  const handleCreate = () => {
    onCreate && onCreate(formState);
  };

  const handleUpdate = () => {
    if (!item) {
      return;
    }

    onUpdate && onUpdate(item.id, formState);
  };

  useEffect(() => {
    setFormState(initForm);
  }, [initForm]);

  return (
    <>
      <FormGroup label="Exchange Type" labelFor="exchange_type" labelInfo="(required)" helperText="Either CEX or DEX">
        <ControlGroup>
          <HTMLSelect name="exchange_type" value={formState.exchange_type} onChange={handleInputChange}>
            <option>Choose an exchange type...</option>
            {exchangeTypes.map(({ id, name }) => (
              <option value={id} key={id}>
                {name}
              </option>
            ))}
          </HTMLSelect>
        </ControlGroup>
      </FormGroup>

      <FormGroup label="Exchange" labelFor="main_exchange" labelInfo="(required)">
        <ControlGroup>
          <HTMLSelect name="main_exchange" value={formState.main_exchange} onChange={handleMainExchangeChange}>
            <option>Choose an exchange...</option>
            {formState.type}
            {supportedExchanges
              .filter(({ type }) => (formState.type ? formState.exchange_type === type : true))
              .map(({ id, name }) => (
                <option value={id} key={id}>
                  {name}
                </option>
              ))}
          </HTMLSelect>
        </ControlGroup>
      </FormGroup>

      {formState.exchange_type === ExchangeType.CEX && (
        <FormGroup label="Type" labelFor="type" labelInfo="(required)" helperText="Main account or subaccount">
          <ControlGroup>
            <HTMLSelect name="type" value={formState.type} onChange={handleInputChange}>
              <option>Choose an account type...</option>
              {accountTypes.map(({ id, name }) => (
                <option value={id} key={id}>
                  {name}
                </option>
              ))}
            </HTMLSelect>
          </ControlGroup>
        </FormGroup>
      )}

      {formState.main_exchange === 'ftx' && formState.type === AccountType.Sub && (
        <FormGroup
          label="Name"
          labelFor="name"
          labelInfo="(required)"
          helperText="Name MUST match with the subaccount's name set on FTX exchange"
        >
          <InputGroup id="text-input" name="name" value={formState.name} onChange={handleInputChange} placeholder="Name of API account" />
        </FormGroup>
      )}

      {(formState.main_exchange !== 'ftx' || formState.type === AccountType.Main) && (
        <FormGroup label="Name" labelFor="name" labelInfo="(required)">
          <InputGroup id="text-input" name="name" value={formState.name} onChange={handleInputChange} placeholder="Name of API account" />
        </FormGroup>
      )}

      {formState.exchange_type === ExchangeType.DEX && (
        <FormGroup label="Blockchain" labelFor="blockchain" labelInfo="(required)">
          <ControlGroup>
            <HTMLSelect name="blockchain" value={formState.blockchain} onChange={handleInputChange}>
              <option>Choose an blockchain...</option>
              {supportedBlockChains.map(({ id, name }) => (
                <option value={id} key={id}>
                  {name}
                </option>
              ))}
            </HTMLSelect>
          </ControlGroup>
        </FormGroup>
      )}

      {formState.exchange_type === ExchangeType.CEX && (
        <FormGroup
          helperText={formState.secret_identifier ? '' : "Prefix for the ENV name whose value is this account's APIs key values."}
          label={'ENV Name Prefix'}
          labelFor="secret_identifier"
          labelInfo="(required)"
        >
          <InputGroup
            id="secret_identifier"
            name="secret_identifier"
            value={formState.secret_identifier}
            onChange={handleIdentifierChange}
            placeholder="An unique identifier for this account"
            autoComplete="off"
          />

          {formState.secret_identifier && (
            <Callout intent="primary" className="mt-2">
              <H5>Set up Environment Variables (ENVs)</H5>
              <p>Please make sure to configure following ENVs for every bot in which this account would trade:</p>
              <ul className="list-disc list-inside">
                <li>
                  <Code>{formState.secret_identifier && `${formState.secret_identifier}_API_KEY`}</Code>
                </li>
                <li>
                  <Code>{formState.secret_identifier && `${formState.secret_identifier}_SECRET_KEY`}</Code>
                </li>
                {formState.main_exchange === 'okex' && (
                  <li>
                    <Code>{formState.secret_identifier && `${formState.secret_identifier}_PASSPHRASE`}</Code>
                  </li>
                )}
              </ul>
            </Callout>
          )}
        </FormGroup>
      )}

      {formState.exchange_type === ExchangeType.DEX && (
        <FormGroup
          helperText={formState.secret_identifier ? '' : "Prefix for the ENV name whose value is this account's private key."}
          label={'ENV Name Prefix'}
          labelFor="secret_identifier"
          labelInfo="(required)"
        >
          <InputGroup
            id="secret_identifier"
            name="secret_identifier"
            value={formState.secret_identifier}
            onChange={handleIdentifierChange}
            placeholder="An unique identifier for this account"
            autoComplete="off"
          />

          {formState.secret_identifier && (
            <Callout intent="primary" className="mt-2">
              <H5>Set up Environment Variables (ENVs)</H5>
              <p>Please make sure to configure following ENVs for every bot in which this account would trade:</p>
              <ul className="list-disc list-inside">
                <li>
                  <Code>{formState.secret_identifier && `${formState.secret_identifier}_PRIVATE_KEY`}</Code>
                </li>
              </ul>
            </Callout>
          )}
        </FormGroup>
      )}

      {formState.exchange_type === ExchangeType.DEX && (
        <FormGroup
          helperText={'Public address of a blockchain account/wallet'}
          label={'Wallet Address'}
          labelFor="address"
          labelInfo="(required)"
        >
          <InputGroup
            id="address"
            name="address"
            value={formState.address}
            onChange={handleInputChange}
            placeholder="Address for this account"
            autoComplete="off"
          />
        </FormGroup>
      )}

      <FormGroup
        helperText="Market making/EC would be disabled when estimated transaction fee (Average gas price x Estimated Gas) is bigger than this setting"
        label={'Max Fee (in Ether) per transaction threshold'}
        labelFor="blur_max_fee_per_tx"
        labelInfo="(required for Blur exchange)"
      >
        <InputGroup
          id="blur_max_fee_per_tx"
          name="blur_max_fee_per_tx"
          value={formState.blur_max_fee_per_tx}
          onChange={handleInputChange}
          placeholder="E.g. 0.002"
          autoComplete="off"
        />
      </FormGroup>

      <FormGroup
        helperText="Market making/EC would be disabled when estimated total transaction fee within current hour is bigger than this setting"
        label={'Max Fee (in Ether) per hour threshold'}
        labelFor="blur_max_fee_per_hour"
        labelInfo="(required for Blur exchange)"
      >
        <InputGroup
          id="blur_max_fee_per_hour"
          name="blur_max_fee_per_hour"
          value={formState.blur_max_fee_per_hour}
          onChange={handleInputChange}
          placeholder="E.g. 0.12"
          autoComplete="off"
        />
      </FormGroup>

      {!item && (
        <Button intent="primary" onClick={handleCreate}>
          Submit
        </Button>
      )}

      {item && (
        <Button intent="primary" onClick={handleUpdate}>
          Update
        </Button>
      )}
    </>
  );
}
