import React, { useContext, useState } from 'react';
import axios from '../../../../shared/custom-axios';
import { ColumnInstance } from 'react-table';

import { BotsManagerContext } from '../../../../contexts/bots-manager';

import { EmergencyCancelOrder, EmergencyCancelOrdersListResponse, TradingPair } from '../../../../shared/interfaces/bot';
import { EmergencyCancelsListLatestPaginatedTable } from './paginated-table';

interface Props {
  tradingPair: TradingPair;
  columns: any[];
  highlighedColumnIds: string[];
  showPartialData?: boolean;
  hoveredColumn: ColumnInstance<EmergencyCancelOrder> | null;
  setHoveredColumn: (column: ColumnInstance<EmergencyCancelOrder> | null) => void;
}

export function EmergencyCancelsListHistory({
  tradingPair,
  columns,
  highlighedColumnIds,
  showPartialData,
  hoveredColumn,
  setHoveredColumn,
}: Props) {
  const { bots } = useContext(BotsManagerContext);
  const currentBot = bots.find((bot) => bot.id === tradingPair.bot_id);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const [totalEntries, setTotalEntries] = React.useState(0);
  const [orders, setOrders] = useState<EmergencyCancelOrder[]>([]);
  const { id: tradingPairId } = tradingPair;

  const fetchData = React.useCallback(
    ({ pageSize, pageIndex, sortBy }) => {
      setLoading(true);

      const basePath = '/api/emergency_cancels/orders';

      const urlParams = new URLSearchParams({
        page_size: pageSize,
        page: pageIndex + 1,
        trading_pair_id: String(tradingPairId),
        bot_id: String(currentBot?.id),
        sort_by: `${sortBy.id}:${sortBy.desc ? 'desc' : 'asc'}`,
      });

      const path = `${basePath}?${urlParams.toString()}`;

      axios.get<EmergencyCancelOrdersListResponse>(path).then((response) => {
        const { entries, total_entries, total_pages } = response.data;

        setOrders(entries);
        setTotalEntries(total_entries);
        setPageCount(total_pages);
        setLoading(false);
      });
    },
    [tradingPairId, currentBot],
  );

  return (
    <>
      <EmergencyCancelsListLatestPaginatedTable
        columns={columns}
        data={orders}
        fetchData={fetchData}
        loading={loading}
        totalEntries={totalEntries}
        pageCount={pageCount}
        highlighedColumnIds={highlighedColumnIds}
        showPartialData={showPartialData}
        hoveredColumn={hoveredColumn}
        getColumnProps={(column) => ({
          onMouseOver: () => {
            setHoveredColumn(column);
          },
          onMouseLeave: () => {
            setHoveredColumn(null);
          },
        })}
      />
    </>
  );
}
