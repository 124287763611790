import { FocusStyleManager, HotkeysProvider } from '@blueprintjs/core';

import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/popover2/lib/css/blueprint-popover2.css';
import '@blueprintjs/select/lib/css/blueprint-select.css';
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';
import './app.css';

import { ExchangesInfoContextProvider } from '../contexts/exchanges-info';
import { AuthContextProvider } from '../contexts/auth';
import { BotsManagerContextProvider } from '../contexts/bots-manager';
import { ThemeContextProvider } from '../contexts/theme';
import { ActiveOrderContextProvider } from '../contexts/active-order';
import { OpportunitySessionContextProvider } from '../contexts/opportunity-session';
import { TimezoneContextProvider } from '../contexts/timezone';
import { RateLimitContextProvider } from '../contexts/rate-limit-info';
import { BotsHealthContextProvider } from '../contexts/bots-health';
import { RestRequestTrackerContextProvider } from '../contexts/rest-request-tracker';
import { UnderContructionContextProvider } from '../contexts/under-construction';
import { ScrollTargetPairContextProvider } from '../contexts/scroll-target-pair';

import { PageHeader } from '../components/common/page-header';
import PageBody from '../components/common/page-body';
import { AppRouter } from './router';

// Remove outline style when focusing on a link/button
FocusStyleManager.onlyShowFocusOnTabs();

export default function App() {
  return (
    <HotkeysProvider>
      <AuthContextProvider>
        <TimezoneContextProvider>
          <ThemeContextProvider>
            <UnderContructionContextProvider>
              <ExchangesInfoContextProvider>
                <BotsManagerContextProvider>
                  <BotsHealthContextProvider>
                    <ScrollTargetPairContextProvider>
                      <ActiveOrderContextProvider>
                        <RestRequestTrackerContextProvider>
                          <OpportunitySessionContextProvider>
                            <RateLimitContextProvider>
                              <PageHeader />

                              <PageBody>
                                <AppRouter />
                              </PageBody>
                            </RateLimitContextProvider>
                          </OpportunitySessionContextProvider>
                        </RestRequestTrackerContextProvider>
                      </ActiveOrderContextProvider>
                    </ScrollTargetPairContextProvider>
                  </BotsHealthContextProvider>
                </BotsManagerContextProvider>
              </ExchangesInfoContextProvider>
            </UnderContructionContextProvider>
          </ThemeContextProvider>
        </TimezoneContextProvider>
      </AuthContextProvider>
    </HotkeysProvider>
  );
}
