import { FormEvent, useCallback, useContext, useEffect, useRef, useState } from 'react';
import axios from '../../shared/custom-axios';
import { Link, useMatch, useSearchParams } from 'react-router-dom';
import { Alignment, HTMLSelect, Label, Navbar, NavbarDivider, Tab, Tabs } from '@blueprintjs/core';

import { PairsSortingOptions, TradingPair } from '../../shared/interfaces/bot';
import { BotsManagerContext } from '../../contexts/bots-manager';

import { TradingPairsList } from '../../components/trading-pair/list/list';
import { Pagination } from '../../components/common/pagination/pagination';

interface TradingPairsListResponse {
  entries: TradingPair[];
  page_number: number;
  page_size: number;
  total_entries: number;
  total_pages: number;
}

const sortByName = (p1: TradingPair, p2: TradingPair) => {
  return p1.name > p2.name ? 1 : -1;
};

const sortByMostRecent = (p1: TradingPair, p2: TradingPair) => {
  return new Date(p1.last_active_at) > new Date(p2.last_active_at) ? -1 : 1;
};

const sortByPrimaryExchange = (p1: TradingPair, p2: TradingPair) => {
  const primary1 = `${p1.primary_instrument.main_exchange}_${p1.primary_instrument.sub_exchange}_${p1.primary_instrument.symbol}`;
  const primary2 = `${p2.primary_instrument.main_exchange}_${p2.primary_instrument.sub_exchange}_${p2.primary_instrument.symbol}`;
  return primary1 > primary2 ? 1 : -1;
};

const sortByPrimaryInstrument = (p1: TradingPair, p2: TradingPair) => {
  const primaryInstrument1 = `${p1.primary_instrument.symbol}_${p1.primary_instrument.main_exchange}_${p1.primary_instrument.sub_exchange}`;
  const primaryInstrument2 = `${p2.primary_instrument.symbol}_${p2.primary_instrument.main_exchange}_${p2.primary_instrument.sub_exchange}`;
  return primaryInstrument1 > primaryInstrument2 ? 1 : -1;
};

const sortBySecondaryExchange = (p1: TradingPair, p2: TradingPair) => {
  const s1 = `${p1.secondary_instrument.main_exchange}_${p1.secondary_instrument.sub_exchange}_${p1.secondary_instrument.symbol}`;
  const s2 = `${p2.secondary_instrument.main_exchange}_${p2.secondary_instrument.sub_exchange}_${p2.secondary_instrument.symbol}`;
  return s1 > s2 ? 1 : -1;
};

const sortBySecondaryInstrument = (p1: TradingPair, p2: TradingPair) => {
  const s1 = `${p1.secondary_instrument.symbol}_${p1.secondary_instrument.main_exchange}_${p1.secondary_instrument.sub_exchange}`;
  const s2 = `${p2.secondary_instrument.symbol}_${p2.secondary_instrument.main_exchange}_${p2.secondary_instrument.sub_exchange}`;
  return s1 > s2 ? 1 : -1;
};

export const TradingPairsIndexPage = (props: any) => {
  const SORT_OPTIONS = [
    { text: 'Most Recent Active', value: PairsSortingOptions.MostRecentActive },
    { text: 'Primary Exchange', value: PairsSortingOptions.PrimaryExchange },
    { text: 'Primary Instrument', value: PairsSortingOptions.PrimaryInstrument },
    { text: 'Secondary Exchange', value: PairsSortingOptions.SecondaryExchange },
    { text: 'Secondary Instrument', value: PairsSortingOptions.SecondaryInstrument },
    { text: 'A → Z', value: PairsSortingOptions.Alphabet },
  ];
  const [sortBy, setSortBy] = useState<PairsSortingOptions>(SORT_OPTIONS[0].value);

  const { currentBot } = useContext(BotsManagerContext);
  const [tradingPairs, setTradingPairs] = useState<TradingPair[]>([]);
  const [pairsSummary, setPairsSummary] = useState<{ [key: string]: { total_entries: number } }>({});
  const match = useMatch(`/bots/${currentBot?.app_id}/trading_pairs/:subPage`);
  const subPage = match?.params.subPage || 'all';
  const activePairSummaryRef = useRef<any>();

  const [searchParams, setSearchParams] = useSearchParams();

  let defaultPageSize = searchParams.get('page_size') ? Number(searchParams.get('page_size')) : 50;
  let page = searchParams.get('page') ? Number(searchParams.get('page')) : 1;
  let pageIndex = page - 1;

  const [pageCount, setPageCount] = useState(0);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [totalEntries, setTotalEntries] = useState(0);

  const handlePageChange = useCallback((pageIndex: number, pageSize: number, status: string) => {
    updateUrl(pageSize, pageIndex);
  }, []);

  const updateUrl = useCallback((pageSize: number, pageIndex: number) => {
    const page = pageIndex + 1;
    searchParams.set('page_size', String(pageSize));
    searchParams.set('page', String(page));

    // Set search params and navigate to new URL
    setSearchParams(searchParams);
  }, []);

  useEffect(() => {
    fetchData({ pageIndex, pageSize, subPage });
  }, [currentBot?.id, searchParams, pageIndex, pageSize, subPage]);

  useEffect(() => {
    if (!currentBot) {
      return;
    }

    axios.get<any>(`/api/trading_pairs/summary?bot_id=${currentBot?.id}`).then((response) => {
      setPairsSummary(response.data);
    });
  }, [currentBot, subPage]);

  const updateTradingPairList = useCallback((tradingPairs: TradingPair[]) => {
    setTradingPairs(sortActivePairList(tradingPairs, sortBy));
  }, []);

  const fetchData = useCallback(
    ({ pageSize, pageIndex }: any) => {
      let path;

      switch (subPage) {
        case 'active': {
          path = `/api/trading_pairs?bot_id=${currentBot?.id}&status=active&page_size=${pageSize}&page=${pageIndex + 1}`;

          break;
        }

        case 'recently_active': {
          path = `/api/trading_pairs/recently_active?bot_id=${currentBot?.id}&page_size=${pageSize}&page=${pageIndex + 1}`;

          break;
        }

        case 'recently_created': {
          path = `/api/trading_pairs/recently_created?bot_id=${currentBot?.id}&page_size=${pageSize}&page=${pageIndex + 1}`;

          break;
        }

        default: {
          path = `/api/trading_pairs?bot_id=${currentBot?.id}&page_size=${pageSize}&page=${pageIndex + 1}`;

          break;
        }
      }

      axios.get<TradingPairsListResponse>(path).then((response) => {
        const { entries, total_entries, total_pages } = response.data;
        setPageCount(total_pages);
        setTotalEntries(total_entries);

        updateTradingPairList(entries);
      });
    },
    [currentBot?.id, subPage, updateTradingPairList],
  );

  const handleRefeshActivePairList = () => {
    activePairSummaryRef?.current.reload();
  };

  const handleSort = (event: FormEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { value } = event.currentTarget;
    const sortingOption = value as PairsSortingOptions;
    setSortBy(sortingOption);
    setTradingPairs(sortActivePairList(tradingPairs, sortingOption));
  };

  const sortActivePairList = (tradingPairs: TradingPair[], sortBy: PairsSortingOptions) => {
    switch (sortBy) {
      case PairsSortingOptions.MostRecentActive:
        return tradingPairs.sort(sortByMostRecent);
      case PairsSortingOptions.Alphabet:
        return tradingPairs.sort(sortByName);
      case PairsSortingOptions.PrimaryExchange:
        return tradingPairs.sort(sortByPrimaryExchange);
      case PairsSortingOptions.PrimaryInstrument:
        return tradingPairs.sort(sortByPrimaryInstrument);
      case PairsSortingOptions.SecondaryExchange:
        return tradingPairs.sort(sortBySecondaryExchange);
      case PairsSortingOptions.SecondaryInstrument:
        return tradingPairs.sort(sortBySecondaryInstrument);
      default:
        return tradingPairs;
    }
  };

  if (!currentBot) {
    return <p>Current bot does not exist</p>;
  }

  return (
    <>
      <Navbar>
        <Navbar.Group>
          <Navbar.Heading className="font-bold">Trading Pairs:</Navbar.Heading>
        </Navbar.Group>

        <Navbar.Group>
          <Tabs animate={true} fill={true} selectedTabId={subPage} large={true} className="">
            <Tab
              id="active"
              title={
                <Link to={`/bots/${currentBot.app_id}/trading_pairs/active`}>
                  {pairsSummary && pairsSummary['active'] ? (
                    <span>
                      Active <span className="text-gray-400">{pairsSummary['active'].total_entries}</span>
                    </span>
                  ) : (
                    <span>
                      Active <span className="text-gray-400">-</span>
                    </span>
                  )}
                </Link>
              }
            />

            <Tab
              id="recently_active"
              title={
                <Link to={`/bots/${currentBot.app_id}/trading_pairs/recently_active`}>
                  {pairsSummary && pairsSummary['recently_active'] ? (
                    <span>
                      Recently Active <span className="text-gray-400">{pairsSummary['recently_active'].total_entries}</span>
                    </span>
                  ) : (
                    <span>
                      Recently Active <span className="text-gray-400">0</span>
                    </span>
                  )}
                </Link>
              }
            />

            <Tab
              id="recently_created"
              title={
                <Link to={`/bots/${currentBot.app_id}/trading_pairs/recently_created`}>
                  {pairsSummary && pairsSummary['recently_created'] ? (
                    <span>
                      New <span className="text-gray-400">{pairsSummary['recently_created'].total_entries}</span>
                    </span>
                  ) : (
                    <span>
                      New <span className="text-gray-400">0</span>
                    </span>
                  )}
                </Link>
              }
            />

            <Tab
              id="all"
              title={
                <Link to={`/bots/${currentBot.app_id}/trading_pairs`}>
                  {pairsSummary && pairsSummary['all'] ? (
                    <span>
                      All <span className="text-gray-400">{pairsSummary['all'].total_entries}</span>
                    </span>
                  ) : (
                    <span>
                      All <span className="text-gray-400">-</span>
                    </span>
                  )}
                </Link>
              }
            />
          </Tabs>
        </Navbar.Group>

        <Navbar.Group align={Alignment.RIGHT}>
          <Pagination
            isLoading={false}
            totalEntries={totalEntries}
            pageCount={pageCount}
            canPreviousPage={pageIndex - 1 >= 0}
            canNextPage={pageIndex + 1 < pageCount}
            pageOptions={[]}
            pageLength={pageCount}
            pageIndex={pageIndex}
            pageSize={pageSize}
            setPageSize={setPageSize}
            gotoPage={(page: number) => {
              handlePageChange(page, pageSize, subPage);
            }}
            nextPage={() => {
              handlePageChange(pageIndex + 1, pageSize, subPage);
            }}
            previousPage={() => {
              handlePageChange(pageIndex - 1, pageSize, subPage);
            }}
          />

          <NavbarDivider />
          <Label className="bp4-label bp4-inline text-xs font-bold" style={{ marginBottom: '0' }}>
            Sort:
            <HTMLSelect name="sort_active_pairs" className="active-pair-sort-by" value={sortBy} onChange={handleSort}>
              {SORT_OPTIONS.map((opt) => (
                <option value={opt.value} key={`sort_by_${opt.value}`}>
                  {opt.text}
                </option>
              ))}
            </HTMLSelect>
          </Label>
        </Navbar.Group>
      </Navbar>
      <div className="mt-8 flex flex-row">
        <TradingPairsList type={subPage} items={tradingPairs} setItems={setTradingPairs} reloadActivePairs={handleRefeshActivePairList} />
      </div>
    </>
  );
};
