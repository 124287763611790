import { Menu, Icon, MenuDivider } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { Link } from 'react-router-dom';

export function MarketsInfoMenu() {
  const dropdownMenu = (
    <Menu>
      <MenuDivider title="CEXs & DEXs" />

      <li>
        <Link to={'/instruments'} className="bp4-menu-item bp4-popover-dismiss">
          <Icon icon="book" />
          <div>Instruments</div>
        </Link>
      </li>

      <MenuDivider title="DEXs-specific" />

      <li>
        <Link to={'/networks'} className="bp4-menu-item bp4-popover-dismiss">
          <Icon icon="globe-network" />
          <div>Networks</div>
        </Link>
      </li>

      <li>
        <Link to={'/dexes'} className="bp4-menu-item bp4-popover-dismiss">
          <Icon icon="exchange" />
          <div>DEXes</div>
        </Link>
      </li>
    </Menu>
  );

  return (
    <>
      <Popover2 content={dropdownMenu} placement="auto" interactionKind="hover" hoverOpenDelay={0} hoverCloseDelay={100}>
        Markets Info
      </Popover2>
    </>
  );
}
