import { useCallback, useContext, useMemo, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Classes, Icon, MenuItem, useHotkeys } from '@blueprintjs/core';
import { ItemPredicate, ItemRenderer, Omnibar } from '@blueprintjs/select';
import axios from '../../../shared/custom-axios';

import { TradingPair, TradingPairsResponse } from '../../../shared/interfaces/bot';
import { useNavigate } from 'react-router-dom';
import { BotsManagerContext } from '../../../contexts/bots-manager';
import { ConnectivityIndicator } from '../../bot/connectivity-indicator';
import { AxiosResponse } from 'axios';

const InnerTradingPairOmnibar = Omnibar.ofType<TradingPair>();

const renderItem: ItemRenderer<TradingPair> = (item, { handleClick, modifiers }) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }

  return (
    <MenuItem
      active={modifiers.active}
      key={item.id}
      onClick={handleClick}
      labelElement={<ConnectivityIndicator active={item.short_mode !== 'stopped' || item.long_mode !== 'stopped'} />}
      text={
        <>
          <span className="block">
            <span className="font-bold">{item.name}</span>
            <span className="text-xs ml-2">#{item.id}</span>
          </span>

          <span className="text-xs block">Last updated {moment(item.updated_at).fromNow()}</span>
        </>
      }
    />
  );
};

const filterItem: ItemPredicate<TradingPair> = (query, tradingPair) => {
  return `${tradingPair.name.toLowerCase()}`.indexOf(query.toLowerCase()) >= 0;
};

export function TradingPairOmnibar() {
  const { currentBot } = useContext(BotsManagerContext);
  const [items, setItems] = useState<TradingPair[]>([]);
  const [isOpen, setIsOpen] = useState(false);

  let navigate = useNavigate();

  const hotkeys = useMemo(
    () => [
      {
        combo: 'shift+f',
        global: true,
        label: 'Open Search dialog',
        onKeyDown: () => setIsOpen(!isOpen),
        preventDefault: true,
      },
    ],
    [],
  );
  const { handleKeyDown, handleKeyUp } = useHotkeys(hotkeys);

  const handleSelect = (tradingPair: TradingPair) => {
    if (currentBot) {
      setIsOpen(false);
      let url = `/bots/${currentBot?.app_id}/trading_pairs?pair_id=${tradingPair?.id}`;
      if (tradingPair.short_mode !== 'stopped' || tradingPair.long_mode !== 'stopped') {
        url = `/bots/${currentBot?.app_id}/trading_pairs/active?pair_id=${tradingPair?.id}`;
      }
      navigate(url);
    }
  };

  const handleQueryChange = (query: string) => {
    fetchTradingPairs({ searchTerm: query, botId: currentBot?.id }, (tradingPairs: TradingPair[]) => {
      const sortedPairs = tradingPairs.sort((t1: TradingPair, t2: TradingPair) => {
        const isT1Working = t1.short_mode !== 'stopped' || t1.long_mode !== 'stopped';
        const isT2Working = t2.short_mode !== 'stopped' || t2.long_mode !== 'stopped';
        if (isT1Working && !isT2Working) {
          return -1;
        } else if (!isT1Working && isT2Working) {
          return 1;
        } else {
          return t1.name > t2.name ? 1 : -1;
        }
      });
      setItems(sortedPairs);
    });
  };

  const fetchTradingPairs = useCallback(
    _.debounce(({ searchTerm, botId }, onHandler) => {
      const path = `/api/trading_pairs?query=${searchTerm}&bot_id=${botId}`;

      axios.get<TradingPairsResponse>(path).then((response: AxiosResponse<TradingPairsResponse>) => {
        const entries = response.data.entries;

        onHandler(entries);
      });
    }, 150),
    [],
  );

  return (
    <div onKeyDown={handleKeyDown} onKeyUp={handleKeyUp}>
      <div
        onClick={() => {
          setIsOpen(true);
        }}
        className={'bp4-menu-item ' + Classes.POPOVER_DISMISS}
      >
        <Icon icon="search" />
      </div>

      <InnerTradingPairOmnibar
        isOpen={isOpen}
        onQueryChange={handleQueryChange}
        itemRenderer={renderItem}
        itemPredicate={filterItem}
        onItemSelect={handleSelect}
        itemsEqual="id"
        items={items}
        onClose={() => setIsOpen(false)}
      />
    </div>
  );
}
