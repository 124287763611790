import { createContext, useContext, useEffect, useState } from 'react';
import { ExchangeType } from '../shared/interfaces/bot';
import { AuthContext } from './auth';

import axios from '../shared/custom-axios';

export interface ExchangeInfo {
  id: string;
  name: string;
  sub_exchanges: Array<SubExchangeInfo>;
  type: ExchangeType;
}

export interface SubExchangeInfo {
  id: string;
  name: string;
}

export const ExchangesInfoContext = createContext<ExchangeInfo[]>([]);

export function ExchangesInfoContextProvider({ children }: any) {
  const { currentUser } = useContext(AuthContext);
  const [exchangesInfo, setExchangesInfo] = useState<ExchangeInfo[]>([]);

  useEffect(() => {
    if (!currentUser) {
      return;
    }

    axios.get<ExchangeInfo[]>('/api/exchanges').then((response) => {
      setExchangesInfo(response.data);
    });
  }, [currentUser]);

  return <ExchangesInfoContext.Provider value={exchangesInfo}>{children}</ExchangesInfoContext.Provider>;
}
