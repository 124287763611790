import { useCallback, useContext, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Channel } from 'phoenix';

import { ThemeMode } from '../shared/interfaces/bot';
import orderService from '../shared/order-service';
import { AuthContext } from '../contexts/auth';
import { BotsManagerContext } from '../contexts/bots-manager';
import { ThemeContext } from '../contexts/theme';

import { RequireAuth } from '../components/auth/require-auth';
import { ActiveSummary } from '../components/trading-pair/active-summary/active-summary';

// Pages
import { HomePage } from '../pages/home/page';
import { LoginPage } from '../pages/login/page';
import { AccountsRoutes } from './routes/accounts';
import { BotSettingsRoutes } from './routes/bot-settings';
import { BotsRoutes } from './routes/bots';
import { ContractStreamsRoutes } from './routes/contract_streams';
import { DexesRoutes } from './routes/dexes';
import { InstrumentsRoutes } from './routes/instruments';
import { MetricsRoutes } from './routes/metrics';
import { NetworksRoutes } from './routes/networks';
import { OrderBooksRoutes } from './routes/order_books';
import { PrivateStreamsRoutes } from './routes/private_streams';
import { TradeFeedRoutes } from './routes/trade_feed';
import { TradingPairsRoutes } from './routes/trading_pairs';
import { UserRoutes } from './routes/user';
import { WickCatcherRoutes } from './routes/wick_catchers';

export function AppRouter() {
  const { currentBot } = useContext(BotsManagerContext);
  const { currentUser } = useContext(AuthContext);
  const { mode: themeMode } = useContext(ThemeContext);

  const activePairSummaryRef = useRef<any>();

  const handleActivePairsUpdate = useCallback(() => {
    activePairSummaryRef?.current.reload();
  }, [activePairSummaryRef]);

  let tradeFeedChannel = useRef<Channel | null>(null);

  useEffect(() => {
    if (!currentUser) {
      return;
    }

    orderService.subscribeTradeFeed(tradeFeedChannel);

    orderService.updateActivePairEvent.on('active_pairs:update', handleActivePairsUpdate);

    return () => {
      orderService.unsubscribeTradeFeed(tradeFeedChannel);

      orderService.updateActivePairEvent.off('active_pairs:update', handleActivePairsUpdate);
    };
  }, [currentUser, handleActivePairsUpdate]);

  return (
    <Router>
      <div id="app" className={`App min-h-screen ${themeMode === ThemeMode.Dark ? 'bp4-dark' : ''}`}>
        <Routes>
          <Route path="/login" element={<LoginPage />} />

          <Route
            path="/trade-feed"
            element={
              <RequireAuth>
                <TradeFeedRoutes />
              </RequireAuth>
            }
          />

          <Route path="/bots">
            <Route
              path="metrics/*"
              element={
                <RequireAuth>
                  <MetricsRoutes />
                </RequireAuth>
              }
            />

            <Route
              path=":appId/wick_catchers/*"
              element={
                <RequireAuth>
                  <WickCatcherRoutes />
                </RequireAuth>
              }
            />

            <Route
              path=":appId/trading_pairs/*"
              element={
                <RequireAuth>
                  <TradingPairsRoutes />
                </RequireAuth>
              }
            />

            <Route
              path=":appId/order_books/*"
              element={
                <RequireAuth>
                  <OrderBooksRoutes />
                </RequireAuth>
              }
            />

            <Route
              path=":appId/private_streams/*"
              element={
                <RequireAuth>
                  <PrivateStreamsRoutes />
                </RequireAuth>
              }
            />

            <Route
              path=":appId/contract_streams/*"
              element={
                <RequireAuth>
                  <ContractStreamsRoutes />
                </RequireAuth>
              }
            />

            <Route
              path=""
              element={
                <RequireAuth>
                  <BotsRoutes />
                </RequireAuth>
              }
            />
          </Route>

          <Route
            path="/accounts/*"
            element={
              <RequireAuth>
                <AccountsRoutes />
              </RequireAuth>
            }
          />

          <Route
            path="/instruments/*"
            element={
              <RequireAuth>
                <InstrumentsRoutes />
              </RequireAuth>
            }
          />

          <Route
            path="/networks/*"
            element={
              <RequireAuth>
                <NetworksRoutes />
              </RequireAuth>
            }
          />

          <Route
            path="/dexes/*"
            element={
              <RequireAuth>
                <DexesRoutes />
              </RequireAuth>
            }
          />

          <Route
            path="/user/*"
            element={
              <RequireAuth>
                <UserRoutes />
              </RequireAuth>
            }
          />

          <Route
            path="/bot-settings/*"
            element={
              <RequireAuth>
                <BotSettingsRoutes />
              </RequireAuth>
            }
          />

          <Route path="/" element={<HomePage />} />
        </Routes>

        {currentBot ? <ActiveSummary ref={activePairSummaryRef} isOpen={false} /> : null}
      </div>
    </Router>
  );
}
