import { useCallback, useContext, useMemo, useState } from 'react';
import _ from 'lodash';
import axios from '../../../shared/custom-axios';
import { Button, Classes, Dialog, Icon, Intent } from '@blueprintjs/core';
import { Link, useNavigate } from 'react-router-dom';

import { ContractType, ExchangeType, Instrument, InstrumentsListResponse } from '../../../shared/interfaces/bot';
import { AppToaster } from '../../../shared/app-toaster';
import { TimezoneContext } from '../../../contexts/timezone';

import { getTradingUrl } from '../utils';
import { InstrumentListTable } from './table';
import { SyncButton } from './sync-button';

export function InstrumentList() {
  const { dateTimeFormater } = useContext(TimezoneContext);
  const [instruments, setInstruments] = useState<Instrument[]>([]);
  const [instrumentToDelete, setInstrumentToDelete] = useState<Instrument | null>(null);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [totalEntries, setTotalEntries] = useState(0);
  const [refreshInstrumentList, setRefreshInstrumentList] = useState(false);
  let navigate = useNavigate();

  const fetchData = useCallback(({ filterTerms, pageSize, pageIndex, searchTerm, sortBy }) => {
    setLoading(true);
    const { mainExchange, subExchange } = filterTerms;

    const urlParams = new URLSearchParams({
      main_exchange: mainExchange,
      sub_exchange: subExchange,
      page_size: pageSize,
      page: pageIndex + 1,
      search: searchTerm,
      sort_by: `${sortBy.id}.${sortBy.desc ? 'desc' : 'asc'}`,
    });

    const path = `/api/instruments?${urlParams.toString()}`;

    axios.get<InstrumentsListResponse>(path).then((response) => {
      const { entries, total_entries, total_pages } = response.data;

      setInstruments(entries);
      setTotalEntries(total_entries);
      setPageCount(total_pages);
      setLoading(false);
    });
  }, []);

  const handleDelete = () => {
    axios
      .delete<InstrumentsListResponse>(`/api/instruments/${instrumentToDelete?.id}`)
      .then(() => {
        setInstruments((prevState) => {
          const newState = prevState.filter(({ id }) => id !== instrumentToDelete?.id);
          return newState;
        });

        const message = 'Instrument deleted successfully!';
        AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 2000 });
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      })
      .finally(() => {
        setInstrumentToDelete(null);
      });
  };

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        id: 'id',
        accessor: (item: Instrument, _rowIndex: number) => {
          return <div style={{ width: '40px', textAlign: 'center' }}>{item.id}</div>;
        },
      },
      {
        Header: 'Exchange',
        id: 'main_exchange',
        accessor: (item: Instrument, _rowIndex: number) => {
          if (['margin', 'futures', 'swap'].indexOf(item.sub_exchange) > -1) {
            return `${_.upperFirst(item.main_exchange)} ${_.upperFirst(item.sub_exchange)} (${_.upperFirst(item.margin_mode)})`;
          }

          if (['defi_swap'].indexOf(item.sub_exchange) > -1) {
            return `${_.upperFirst(item.main_exchange)}`;
          }

          return `${_.upperFirst(item.main_exchange)} ${_.upperFirst(item.sub_exchange)}`;
        },
      },
      {
        Header: 'Symbol',
        id: 'symbol',
        accessor: (item: Instrument, _rowIndex: number) => {
          const tradingUrl = getTradingUrl(item);

          if (tradingUrl !== '') {
            return (
              <a target="_blank" href={getTradingUrl(item)} rel="noreferrer">
                {item.symbol}
              </a>
            );
          }

          return item.symbol;
        },
      },
      {
        Header: 'List Time',
        id: 'list_time',
        accessor: (item: Instrument, _rowIndex: number) => {
          if (!item.list_time) {
            return '';
          }

          const dateTime = new Date(item.list_time + 'Z');
          return dateTimeFormater.format(dateTime);
        },
      },
      {
        Header: 'Expiry Type',
        accessor: 'expiry_type',
      },
      {
        Header: 'Expiry Date',
        id: 'expiry_date',
        accessor: (item: Instrument, _rowIndex: number) => {
          if (!item.expiry_date) {
            return '';
          }

          const dateTime = new Date(item.expiry_date + 'Z');
          return dateTimeFormater.format(dateTime);
        },
      },
      {
        Header: 'Contract Type',
        id: 'contract_type',
        accessor: (item: Instrument, _rowIndex: number) => {
          if (['futures', 'swap'].indexOf(item.sub_exchange) > -1) {
            if (item.contract_type === ContractType.Linear) {
              return `${item.quote_currency}-margined (Linear)`;
            }

            if (item.contract_type === ContractType.Inverse) {
              return 'Coined-margined (Inverse)';
            }
          }

          return '';
        },
      },

      {
        Header: 'Contract Size',
        id: 'contract_size',
        accessor: (item: Instrument, _rowIndex: number) => {
          if (['futures', 'swap'].indexOf(item.sub_exchange) > -1) {
            if (item.contract_type === ContractType.Linear) {
              if (item.contract_size === 1) {
                return '';
              }

              return `${(+item.contract_size).toLocaleString('en-US', { maximumFractionDigits: 10 })} ${item.base_currency}`;
            }

            if (item.contract_type === ContractType.Inverse) {
              return `${(+item.contract_size).toLocaleString('en-US', { maximumFractionDigits: 10 })} ${item.quote_currency}`;
            }
          }

          return '';
        },
      },
      {
        Header: 'Tick Size',
        accessor: 'tick_size',
      },
      {
        Header: 'Lot Size',
        id: 'lot_size',
        accessor: (item: Instrument, _rowIndex: number) => {
          if (item.exchange_type === ExchangeType.DEX) return;

          return <div style={{ width: '80px' }}>{`${item.lot_size} ${item.volume_currency}`}</div>;
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Base Currency',
        accessor: 'base_currency',
      },
      {
        Header: 'Quote Currency',
        accessor: 'quote_currency',
      },
      {
        Header: 'Volume Currency',
        accessor: 'volume_currency',
      },
      {
        id: 'action',
        Header: '',
        accessor: (item: Instrument, _rowIndex: number) => {
          return (
            <div className="flex">
              <Button icon="edit" minimal={true} intent={Intent.PRIMARY} small={true} onClick={() => editInstrument(item.id)} />
              <Button
                icon="trash"
                minimal={true}
                intent={Intent.DANGER}
                small={true}
                onClick={() => confirmToDeleteInstrument(item)}
                className="ml-1"
              />
            </div>
          );
        },
      },
    ],
    [dateTimeFormater],
  );

  const afterSyncInstrument = () => {
    setRefreshInstrumentList(!refreshInstrumentList);
  };

  const confirmToDeleteInstrument = (instrument: Instrument) => {
    setInstrumentToDelete(instrument);
  };

  const editInstrument = (id: number) => {
    navigate(`/instruments/${id}/edit`);
  };

  return (
    <>
      <div className="mt-1">
        <Link to={'/instruments/new'} className="mr-3 bp4-button">
          <Icon icon="add" />
          <span>New</span>
        </Link>
        <SyncButton afterSync={afterSyncInstrument}></SyncButton>
      </div>

      <div className="mt-5">
        <InstrumentListTable
          needToRefresh={refreshInstrumentList}
          columns={columns}
          data={instruments}
          fetchData={fetchData}
          loading={loading}
          totalEntries={totalEntries}
          pageCount={pageCount}
        />
      </div>

      <Dialog
        portalContainer={document.getElementById('app') || undefined}
        title={`Delete Instrument ${instrumentToDelete?.main_exchange}-${instrumentToDelete?.symbol}`}
        transitionDuration={500}
        isOpen={!!instrumentToDelete}
        onClose={() => {
          setInstrumentToDelete(null);
        }}
        style={{ minWidth: '300px' }}
      >
        <div className={Classes.DIALOG_BODY}>
          <span>Are you sure that you want to delete this instrument?</span>
        </div>

        <div className={Classes.DIALOG_FOOTER}>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
            <Button
              onClick={() => {
                setInstrumentToDelete(null);
              }}
              className={Classes.POPOVER_DISMISS}
              style={{ marginRight: 10 }}
            >
              Cancel
            </Button>
            <Button onClick={handleDelete} intent={Intent.DANGER} className={Classes.POPOVER_DISMISS}>
              Delete
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
}
