import { FormEvent, useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Button, FormGroup, InputGroup, Intent } from '@blueprintjs/core';
import axios from '../../../shared/custom-axios';

import { AppToaster } from '../../../shared/app-toaster';
import { BotsManagerContext } from '../../../contexts/bots-manager';
import { OrderBook, Exchange, Instrument } from '../../../shared/interfaces/bot';
import { ExchangeSelect } from '../../common/exchange-select/exchange-select';
import { InstrumentSelect } from '../../common/instrument-select/instrument-select';
import { useInstruments } from '../../instrument/use-instruments';

interface ContractStreamFormState {
  id?: number;
  name: string;
  description: string;
  exchange_type: 'cex' | 'dex' | 'all';
  main_exchange: string;
  sub_exchange: string;
  status: string;
  instrument_id?: string;
  bot_id: number | undefined;
}

export function ContractStreamForm() {
  const { currentBot } = useContext(BotsManagerContext);

  const initForm: ContractStreamFormState = {
    name: '',
    description: '',
    status: '',
    exchange_type: 'all',
    main_exchange: '',
    sub_exchange: '',
    instrument_id: '',
    bot_id: currentBot?.id,
  };

  const [formState, setFormState] = useState(initForm);
  const [successRedirect, setSuccessRedirect] = useState(false);
  const [selectedInstrument, setSelectedInstrument] = useState<Instrument | null>(null);
  const [query, setQuery] = useState('');

  const { instrument_id, main_exchange, sub_exchange } = formState;
  const instruments = useInstruments({ main_exchange, sub_exchange, query });

  const handleInputChange = (event: FormEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = event.currentTarget;

    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    axios
      .post<OrderBook[]>(`/api/contract_streams`, {
        contract_stream: formState,
      })
      .then(() => {
        setSuccessRedirect(true);
        const message = 'Contract stream created successfully!';
        AppToaster.show({
          message: message,
          icon: 'tick',
          intent: Intent.SUCCESS,
          timeout: 2000,
        });
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.data);
        AppToaster.show({
          message: message,
          icon: 'warning-sign',
          intent: Intent.DANGER,
          timeout: 3500,
        });
      });
  };

  useEffect(() => {
    // Reset the sub_exchange field when main_exchange is changed
    setFormState((prevState) => ({
      ...prevState,
      sub_exchange: '',
    }));
  }, [currentBot?.id]);

  useEffect(() => {
    // Auto prefill a suggested name for the order book based on selected instrument
    const selectedInstrument = instruments.find(({ id }) => id === Number(instrument_id));

    setFormState((prevState) => ({
      ...prevState,
      name: selectedInstrument ? selectedInstrument.symbol : '',
    }));
  }, [instrument_id, instruments]);

  return (
    <>
      {successRedirect && <Navigate to={`/bots/${currentBot?.app_id}/contract_streams`} replace />}
      <h2 className="text-lg font-bold my-1">New contract stream</h2>

      <FormGroup label="Exchange" labelInfo="(required)">
        <ExchangeSelect
          mainExchange={formState.main_exchange}
          subExchange={formState.sub_exchange}
          handleSelect={(item: Exchange) => {
            setFormState((prevState) => ({
              ...prevState,
              exchange_type: item.type,
              main_exchange: item.main_exchange,
              sub_exchange: item.sub_exchange,
            }));
          }}
        />
      </FormGroup>

      <FormGroup label="Instrument" labelFor="instrument_id" labelInfo="(required)" helperText="Which market type you want to connect to?">
        <InstrumentSelect
          selectedItem={selectedInstrument}
          items={instruments}
          disabled={!formState.main_exchange || !formState.sub_exchange}
          handleSelect={(item: Instrument) => {
            setFormState((prevState) => ({
              ...prevState,
              instrument_id: String(item.id),
            }));

            setSelectedInstrument(item);
          }}
          query={query}
          handleQueryChange={(v) => {
            setQuery(v);
          }}
        />
      </FormGroup>

      <FormGroup label="Name" labelFor="name" labelInfo="(required)">
        <InputGroup id="name" name="name" value={formState.name} onChange={handleInputChange} placeholder="" />
      </FormGroup>

      <FormGroup label="Description" labelFor="description">
        <InputGroup id="description" name="description" value={formState.description} onChange={handleInputChange} placeholder="" />
      </FormGroup>

      <Button intent="primary" onClick={handleSubmit}>
        Submit
      </Button>
    </>
  );
}
