import { ChangeEvent, FormEvent, useCallback, useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import axios from '../../../shared/custom-axios';
import { Button, Collapse, FormGroup, HTMLSelect, InputGroup, Intent, NumericInput, TextArea } from '@blueprintjs/core';

import {
  Bot,
  ContractStream,
  ContractStreamsListResponse,
  Exchange,
  ExchangeType,
  InstrumentsListResponse,
  PrivateStream,
  PrivateStreamsListResponse,
  StrategyType,
} from '../../../shared/interfaces/bot';
import { AppToaster } from '../../../shared/app-toaster';
import { ExchangeSelect } from '../../common/exchange-select/exchange-select';
import { InstrumentSelect } from '../../common/instrument-select/instrument-select';
import { OrderBookSelect } from '../../common/order-book-select/order-book-select';
import { PrivateStreamSelect } from '../../common/private-stream-select/private-stream-select';
import { BotsManagerContext } from '../../../contexts/bots-manager';

import { Account, AccountsListResponse, Instrument, OrderBook, OrderBooksListResponse, TradingPair } from '../../../shared/interfaces/bot';
import { ContractStreamSelect } from '../../common/contract-stream-select/contract-stream-select';
import { useReloadAccounts } from './hooks/use-reload-accounts';
import { useReloadPrivateStreams } from './hooks/use-reload-private-streams';
import { useReloadContractStreams } from './hooks/use-reload-contract-streams';
import { useDefaultPrivateStream } from './hooks/use-default-private-stream';
import { useDefaultContractStream } from './hooks/use-default-contract-stream';
import { useDefaultOrderBook } from './hooks/use-default-order-book';
import { useFilterSupportedTradingCurrencies } from './hooks/use-filter-supported-currencies';
import { BotSelect } from '../../common/bot-select/bot-select';

const DEFAULT_CURRENCY = 'USD';

const supportedStrategyTypes = [
  { id: StrategyType.Normal, title: 'Normal' },
  { id: StrategyType.PairsTrading, title: 'Pairs Trading' },
  { id: StrategyType.NFTMarketMaking, title: 'NFT Market Making' },
];

export interface TradingPairFormState {
  id?: number;
  name: string;
  description: string;
  strategy_type: StrategyType;
  short_mode: string;
  long_mode: string;
  primary_main_exchange: string;
  primary_sub_exchange: string;
  primary_account_id: string;
  primary_private_stream_id: string;
  primary_contract_stream_id: string;
  primary_instrument_id: string;
  primary_order_book_id: string;
  secondary_main_exchange: string;
  secondary_sub_exchange: string;
  secondary_account_id: string;
  secondary_private_stream_id: string;
  secondary_contract_stream_id: string;
  secondary_instrument_id: string;
  secondary_order_book_id: string;
  tertiary_main_exchange: string;
  tertiary_sub_exchange: string;
  tertiary_account_id: string;
  tertiary_private_stream_id: string;
  tertiary_contract_stream_id: string;
  tertiary_instrument_id: string;
  tertiary_order_book_id: string;
  currency: string;
  defi_quota: string;
  defi_replenish_delay: string;
  default_debounce: number | undefined;
  hedge_bot_id: number | undefined;
  bot_id: number | undefined;
}

// TODO: Ugly code
function initFormData(bot: Bot | null | undefined, item: TradingPair | null | undefined): TradingPairFormState {
  if (item) {
    return {
      id: item.id,
      name: item.name,
      description: item.description,
      strategy_type: item.strategy_type,
      short_mode: item.short_mode,
      long_mode: item.long_mode,
      primary_main_exchange: item.primary_instrument.main_exchange,
      primary_sub_exchange: item.primary_instrument.sub_exchange,
      primary_instrument_id: String(item.primary_instrument.id),
      primary_order_book_id: item.primary_order_book && String(item.primary_order_book.id),
      primary_account_id: String(item.primary_account.id),
      primary_private_stream_id: item.primary_private_stream && String(item.primary_private_stream.id),
      primary_contract_stream_id: item.primary_contract_stream && String(item.primary_contract_stream.id),
      secondary_main_exchange: item.secondary_instrument.main_exchange,
      secondary_sub_exchange: item.secondary_instrument.sub_exchange,
      secondary_instrument_id: String(item.secondary_instrument.id),
      secondary_order_book_id: item.secondary_order_book && String(item.secondary_order_book.id),
      secondary_account_id: String(item.secondary_account.id),
      secondary_private_stream_id: item.secondary_private_stream && String(item.secondary_private_stream.id),
      secondary_contract_stream_id: item.secondary_contract_stream && String(item.secondary_contract_stream.id),
      tertiary_main_exchange: item.tertiary_instrument && item.tertiary_instrument.main_exchange,
      tertiary_sub_exchange: item.tertiary_instrument && item.tertiary_instrument.sub_exchange,
      tertiary_instrument_id: item.tertiary_instrument && String(item.tertiary_instrument.id),
      tertiary_order_book_id: item.tertiary_order_book && String(item.tertiary_order_book.id),
      tertiary_account_id: item.tertiary_account && String(item.tertiary_account.id),
      tertiary_private_stream_id: item.tertiary_private_stream && String(item.tertiary_private_stream.id),
      tertiary_contract_stream_id: item.tertiary_contract_stream && String(item.tertiary_contract_stream.id),
      currency: item.currency,
      defi_quota: item.defi_quota,
      defi_replenish_delay: item.defi_replenish_delay,
      default_debounce: item.default_debounce,
      hedge_bot_id: item.hedge_bot_id || bot?.id,
      bot_id: bot?.id,
    };
  }

  return {
    name: '',
    description: '',
    strategy_type: StrategyType.Normal,
    short_mode: 'stopped',
    long_mode: 'stopped',
    primary_main_exchange: '',
    primary_sub_exchange: '',
    primary_instrument_id: '',
    primary_order_book_id: '',
    primary_account_id: '',
    primary_private_stream_id: '',
    primary_contract_stream_id: '',
    secondary_main_exchange: '',
    secondary_sub_exchange: '',
    secondary_instrument_id: '',
    secondary_order_book_id: '',
    secondary_account_id: '',
    secondary_private_stream_id: '',
    secondary_contract_stream_id: '',
    tertiary_main_exchange: '',
    tertiary_sub_exchange: '',
    tertiary_instrument_id: '',
    tertiary_order_book_id: '',
    tertiary_account_id: '',
    tertiary_private_stream_id: '',
    tertiary_contract_stream_id: '',
    currency: '',
    defi_quota: '',
    defi_replenish_delay: '',
    default_debounce: 30000,
    hedge_bot_id: 9,
    bot_id: bot?.id,
  };
}

interface Props {
  item?: TradingPair | null;
  setItem?: (item: TradingPair | null) => void;
  onReloadItem?: (itemId: number) => void;
  afterCreatePair?: (tradingPair: TradingPair) => void;
}

export function TradingPairForm({ item, setItem, onReloadItem, afterCreatePair }: Props) {
  const { currentBot } = useContext(BotsManagerContext);

  const initForm: TradingPairFormState = initFormData(currentBot, item);

  let initSelectedPrimaryInstrument: Instrument | null = null;
  let initPrimaryPrivateStreams: PrivateStream[] = [];
  let initPrimaryContractStreams: ContractStream[] = [];
  let initPrimaryOrderBooks: OrderBook[] = [];

  let initSelectedSecondaryInstrument: Instrument | null = null;
  let initSecondaryPrivateStreams: PrivateStream[] = [];
  let initSecondaryContractStreams: ContractStream[] = [];
  let initSecondaryOrderBooks: OrderBook[] = [];

  let initSelectedTertiaryInstrument: Instrument | null = null;
  let initTertiaryPrivateStreams: PrivateStream[] = [];
  let initTertiaryContractStreams: ContractStream[] = [];
  let initTertiaryOrderBooks: OrderBook[] = [];

  if (item) {
    initSelectedPrimaryInstrument = item.primary_instrument;
    initPrimaryPrivateStreams = item.primary_private_stream ? [item.primary_private_stream] : [];
    initPrimaryOrderBooks = item.primary_order_book ? [item.primary_order_book] : [];

    initSelectedSecondaryInstrument = item.secondary_instrument;
    initSecondaryPrivateStreams = item.secondary_private_stream ? [item.secondary_private_stream] : [];
    initSecondaryOrderBooks = item.secondary_order_book ? [item.secondary_order_book] : [];

    initSelectedTertiaryInstrument = item.tertiary_instrument;
    initTertiaryPrivateStreams = item.tertiary_private_stream ? [item.tertiary_private_stream] : [];
    initTertiaryOrderBooks = item.tertiary_order_book ? [item.tertiary_order_book] : [];
  }

  const [formState, setFormState] = useState(initForm);

  const [primaryAccounts, setPrimaryAccounts] = useState<Account[]>([]);
  const [primaryPrivateStreams, setPrimaryPrivateStreams] = useState<PrivateStream[]>(initPrimaryPrivateStreams);
  const [primaryContractStreams, setPrimaryContractStreams] = useState<ContractStream[]>(initPrimaryContractStreams);
  const [primaryInstruments, setPrimaryInstruments] = useState<Instrument[]>([]);
  const [primaryOrderBooks, setPrimaryOrderBooks] = useState<OrderBook[]>(initPrimaryOrderBooks);
  const [showPrimaryAdvancedSettings, setShowPrimaryAdvancedSettings] = useState(false);
  const [primaryInstrumentQuery, setPrimaryInstrumentQuery] = useState('');
  const [selectedPrimaryInstrument, setSelectedPrimaryInstrument] = useState<Instrument | null>(initSelectedPrimaryInstrument);

  const [secondaryAccounts, setSecondaryAccounts] = useState<Account[]>([]);
  const [secondaryPrivateStreams, setSecondaryPrivateStreams] = useState<PrivateStream[]>(initSecondaryPrivateStreams);
  const [secondaryContractStreams, setSecondaryContractStreams] = useState<ContractStream[]>(initSecondaryContractStreams);
  const [secondaryInstruments, setSecondaryInstruments] = useState<Instrument[]>([]);
  const [secondaryOrderBooks, setSecondaryOrderBooks] = useState<OrderBook[]>(initSecondaryOrderBooks);
  const [showSecondaryAdvancedSettings, setShowSecondaryAdvancedSettings] = useState(false);
  const [secondaryInstrumentQuery, setSecondaryInstrumentQuery] = useState('');
  const [selectedSecondaryInstrument, setSelectedSecondaryInstrument] = useState<Instrument | null>(initSelectedSecondaryInstrument);

  const [tertiaryAccounts, setTertiaryAccounts] = useState<Account[]>([]);
  const [tertiaryPrivateStreams, setTertiaryPrivateStreams] = useState<PrivateStream[]>(initTertiaryPrivateStreams);
  const [tertiaryContractStreams, setTertiaryContractStreams] = useState<ContractStream[]>(initTertiaryContractStreams);
  const [tertiaryInstruments, setTertiaryInstruments] = useState<Instrument[]>([]);
  const [tertiaryOrderBooks, setTertiaryOrderBooks] = useState<OrderBook[]>(initTertiaryOrderBooks);
  const [showTertiaryAdvancedSettings, setShowTertiaryAdvancedSettings] = useState(false);
  const [tertiaryInstrumentQuery, setTertiaryInstrumentQuery] = useState('');
  const [selectedTertiaryInstrument, setSelectedTertiaryInstrument] = useState<Instrument | null>(initSelectedTertiaryInstrument);

  // const [supportedCurrencies, setSupportedCurrencies] = useState<string[]>([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [validationError, setValidationError] = useState(new Map());

  const {
    strategy_type,
    primary_main_exchange,
    primary_sub_exchange,
    primary_instrument_id,
    primary_order_book_id,
    primary_account_id,
    secondary_main_exchange,
    secondary_sub_exchange,
    secondary_instrument_id,
    secondary_order_book_id,
    secondary_account_id,
    tertiary_main_exchange,
    tertiary_sub_exchange,
    tertiary_instrument_id,
    tertiary_order_book_id,
    tertiary_account_id,
    currency,
  } = formState;

  const handleInputChange = (event: FormEvent<HTMLInputElement | HTMLSelectElement> | ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = event.currentTarget;

    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleNumberValueChange = (valueAsNumber: number, valueAsString: string, inputElement: HTMLInputElement | null) => {
    if (inputElement) {
      const { name } = inputElement;

      setFormState((prevState) => ({
        ...prevState,
        [name]: Number(valueAsString.replace(/\D/g, '')),
      }));
    }
  };

  const handleValidate = useCallback(() => {
    if (selectedPrimaryInstrument && selectedSecondaryInstrument) {
      setValidationError(() => {
        let validationError = new Map();

        let isCompatiblePairs = selectedPrimaryInstrument.backing_base_currency === selectedSecondaryInstrument.backing_base_currency;

        if (formState.strategy_type === StrategyType.Normal && !isCompatiblePairs) {
          validationError.set(
            'strategy_type',
            `Normal pair must have compatible primary & secondary instruments: ${selectedPrimaryInstrument.backing_base_currency} != ${selectedSecondaryInstrument.backing_base_currency}`,
          );
        }

        return validationError;
      });
    }
  }, [formState.strategy_type, selectedPrimaryInstrument, selectedSecondaryInstrument]);

  const handleCreate = () => {
    if (validationError.size === 0) {
      setIsProcessing(true);
      axios
        .post<TradingPair>(`/api/trading_pairs`, { trading_pair: formState })
        .then((res) => {
          if (afterCreatePair) {
            afterCreatePair(res.data);
          }
          const message = 'Trading Pair created successfully!';
          AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 2000 });
        })
        .catch((error) => {
          const message = JSON.stringify(error.response.data);
          AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
        })
        .finally(() => {
          setIsProcessing(false);
        });
    }
  };

  const handleUpdate = () => {
    if (validationError.size === 0) {
      axios
        .put<TradingPair>(`/api/trading_pairs/${formState.id}`, { trading_pair: formState })
        .then(() => {
          setItem && setItem(null);
          item && onReloadItem && onReloadItem(item.id);

          const message = 'Trading Pair updated successfully!';
          AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 2000 });
        })
        .catch((error) => {
          const message = JSON.stringify(error.response.data);
          AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
        });
    }
  };

  const fetchInstruments = useCallback(
    _.debounce((main_exchange, sub_exchange, searchTerm, onHandler) => {
      let path = `/api/instruments?main_exchange=${main_exchange}&sub_exchange=${sub_exchange}&page_size=200`;

      if (searchTerm && searchTerm !== '') {
        path = `${path}&search=${searchTerm}`;
      }

      axios.get<InstrumentsListResponse>(path).then((response) => {
        const { entries } = response.data;

        if (searchTerm && searchTerm !== '') {
          entries.sort((o1, o2) => {
            if (o1.symbol.startsWith(searchTerm) && o2.symbol.startsWith(searchTerm)) {
              return o1.id - o2.id;
            }

            if (o1.symbol.startsWith(searchTerm) && !o2.symbol.startsWith(searchTerm)) {
              return -1;
            }

            if (!o1.symbol.startsWith(searchTerm) && o2.symbol.startsWith(searchTerm)) {
              return 1;
            }
            return o1.id - o2.id;
          });
        }

        onHandler(entries);
      });
    }, 500),
    [],
  );

  const handleSelectPrimaryInstrument = (item: Instrument) => {
    setFormState((prevState) => ({
      ...prevState,
      primary_instrument_id: String(item.id),
      primary_order_book_id: '',
    }));

    setSelectedPrimaryInstrument(item);

    if (formState.strategy_type === StrategyType.Normal) {
      fetchInstruments(secondary_main_exchange, secondary_sub_exchange, item.base_currency, setSecondaryInstruments);
    }

    if (item.exchange_type === ExchangeType.CEX || item.main_exchange === 'blur') {
      axios.get<OrderBooksListResponse>(`/api/order_books?instrument_id=${item.id}&bot_id=${currentBot?.id}`).then((response) => {
        setPrimaryOrderBooks(response.data.data);
      });
    }

    if (item.exchange_type === ExchangeType.DEX) {
      axios.get<AccountsListResponse>(`/api/accounts?blockchain=${item.dex_network.blockchain}`).then((response) => {
        setPrimaryAccounts(response.data.data);
      });
    }
  };

  const handleSelectSecondaryInstrument = (item: Instrument) => {
    setFormState((prevState) => ({
      ...prevState,
      secondary_instrument_id: String(item.id),
      secondary_order_book_id: '',
    }));

    setSelectedSecondaryInstrument(item);

    if (formState.strategy_type === StrategyType.Normal) {
      fetchInstruments(primary_main_exchange, primary_sub_exchange, item.base_currency, setPrimaryInstruments);
    }

    if (item.exchange_type === ExchangeType.CEX) {
      axios.get<OrderBooksListResponse>(`/api/order_books?instrument_id=${item.id}&bot_id=${currentBot?.id}`).then((response) => {
        setSecondaryOrderBooks(response.data.data);
      });
    }

    if (item.exchange_type === ExchangeType.DEX) {
      axios.get<AccountsListResponse>(`/api/accounts?blockchain=${item.dex_network.blockchain}`).then((response) => {
        setSecondaryAccounts(response.data.data);
      });
    }
  };

  const handleSelectTertiaryInstrument = (item: Instrument) => {
    setFormState((prevState) => ({
      ...prevState,
      tertiary_instrument_id: String(item.id),
      tertiary_order_book_id: '',
    }));

    setSelectedTertiaryInstrument(item);

    if (item.exchange_type === ExchangeType.CEX) {
      axios.get<OrderBooksListResponse>(`/api/order_books?instrument_id=${item.id}&bot_id=${currentBot?.id}`).then((response) => {
        setTertiaryOrderBooks(response.data.data);
      });
    }

    if (item.exchange_type === ExchangeType.DEX) {
      axios.get<AccountsListResponse>(`/api/accounts?blockchain=${item.dex_network.blockchain}`).then((response) => {
        setTertiaryAccounts(response.data.data);
      });
    }
  };

  useEffect(() => {
    handleValidate();
  }, [formState.strategy_type, selectedPrimaryInstrument, selectedSecondaryInstrument, selectedTertiaryInstrument, handleValidate]);

  useEffect(() => {
    if (!item || !item.id) {
      return;
    }

    onReloadItem && onReloadItem(item.id);
  }, [item, onReloadItem]);

  useEffect(() => {
    if (!currentBot) {
      return;
    }

    if (formState.bot_id !== currentBot.id) {
      setFormState((prevState) => ({
        ...prevState,
        bot_id: currentBot.id,
      }));
    }
  }, [currentBot, formState.bot_id]);

  // Primary

  useReloadAccounts({
    mainExchange: primary_main_exchange,
    subExchange: primary_sub_exchange,
    selectedInstrument: selectedPrimaryInstrument,
    onDataReady: (items) => {
      setPrimaryAccounts(items);
    },
  });

  useReloadPrivateStreams({
    botId: currentBot?.id,
    instrumentId: primary_instrument_id,
    accountId: primary_account_id,
    selectedInstrument: selectedPrimaryInstrument,
    onDataReady: (items) => {
      setPrimaryPrivateStreams(items);
    },
  });

  useReloadContractStreams({
    botId: currentBot?.id,
    instrumentId: primary_instrument_id,
    accountId: primary_account_id,
    selectedInstrument: selectedPrimaryInstrument,
    onDataReady: (items) => {
      setPrimaryContractStreams(items);

      setFormState((prevState) => ({
        ...prevState,
        primary_contract_stream_id: '',
      }));
    },
  });

  useDefaultPrivateStream({
    botId: currentBot?.id,
    instrumentId: primary_instrument_id,
    accountId: primary_account_id,
    loadedInstruments: primaryInstruments,
    loadedPrivateStreams: primaryPrivateStreams,
    onAfterSelectDefault: (item) => {
      setFormState((prevState) => ({
        ...prevState,
        primary_private_stream_id: String(item.id),
      }));
    },
    onAfterCreateDefault: () => {
      axios
        .get<PrivateStreamsListResponse>(
          `/api/private_streams?instrument_id=${primary_instrument_id}&account_id=${primary_account_id}&bot_id=${currentBot?.id}`,
        )
        .then((response) => {
          setPrimaryPrivateStreams(response.data.data);
        });
    },
  });

  useDefaultContractStream({
    botId: currentBot?.id,
    instrumentId: primary_instrument_id,
    loadedInstruments: primaryInstruments,
    loadedContractStreams: primaryContractStreams,
    onAfterSelectDefault: (item) => {
      setFormState((prevState) => ({
        ...prevState,
        primary_contract_stream_id: String(item.id),
      }));
    },
    onAfterCreateDefault: () => {
      axios
        .get<ContractStreamsListResponse>(`/api/contract_streams?instrument_id=${primary_instrument_id}&bot_id=${currentBot?.id}`)
        .then((response) => {
          setPrimaryContractStreams(response.data.data);
        });
    },
  });

  useDefaultOrderBook({
    botId: currentBot?.id,
    instrumentId: primary_instrument_id,
    selectedInstrument: selectedPrimaryInstrument,
    loadedOrderBooks: primaryOrderBooks,
    onAfterSelectDefault: (item) => {
      setFormState((prevState) => ({
        ...prevState,
        primary_order_book_id: String(item.id),
      }));
    },
    onAfterCreateDefault: () => {
      axios
        .get<OrderBooksListResponse>(`/api/order_books?instrument_id=${primary_instrument_id}&bot_id=${currentBot?.id}`)
        .then((response) => {
          setPrimaryOrderBooks(response.data.data);
        });
    },
  });

  // Secondary

  useReloadAccounts({
    mainExchange: secondary_main_exchange,
    subExchange: secondary_sub_exchange,
    selectedInstrument: selectedSecondaryInstrument,
    onDataReady: (items) => {
      setSecondaryAccounts(items);
    },
  });

  useReloadPrivateStreams({
    botId: currentBot?.id,
    instrumentId: secondary_instrument_id,
    accountId: secondary_account_id,
    selectedInstrument: selectedSecondaryInstrument,
    onDataReady: (items) => {
      setSecondaryPrivateStreams(items);
    },
  });

  useReloadContractStreams({
    botId: currentBot?.id,
    instrumentId: secondary_instrument_id,
    accountId: secondary_account_id,
    selectedInstrument: selectedSecondaryInstrument,
    onDataReady: (items) => {
      setSecondaryContractStreams(items);

      setFormState((prevState) => ({
        ...prevState,
        secondary_contract_stream_id: '',
      }));
    },
  });

  useDefaultPrivateStream({
    botId: currentBot?.id,
    instrumentId: secondary_instrument_id,
    accountId: secondary_account_id,
    loadedInstruments: secondaryInstruments,
    loadedPrivateStreams: secondaryPrivateStreams,
    onAfterSelectDefault: (item) => {
      setFormState((prevState) => ({
        ...prevState,
        secondary_private_stream_id: String(item.id),
      }));
    },
    onAfterCreateDefault: () => {
      axios
        .get<PrivateStreamsListResponse>(
          `/api/private_streams?instrument_id=${secondary_instrument_id}&account_id=${secondary_account_id}&bot_id=${currentBot?.id}`,
        )
        .then((response) => {
          let streams = response.data.data ? response.data.data : [];

          setSecondaryPrivateStreams(streams);
        });
    },
  });

  useDefaultContractStream({
    botId: currentBot?.id,
    instrumentId: secondary_instrument_id,
    loadedInstruments: secondaryInstruments,
    loadedContractStreams: secondaryContractStreams,
    onAfterSelectDefault: (item) => {
      setFormState((prevState) => ({
        ...prevState,
        secondary_contract_stream_id: String(item.id),
      }));
    },
    onAfterCreateDefault: () => {
      axios
        .get<ContractStreamsListResponse>(`/api/contract_streams?instrument_id=${secondary_instrument_id}&bot_id=${currentBot?.id}`)
        .then((response) => {
          setSecondaryContractStreams(response.data.data);
        });
    },
  });

  useDefaultOrderBook({
    botId: currentBot?.id,
    instrumentId: secondary_instrument_id,
    selectedInstrument: selectedSecondaryInstrument,
    loadedOrderBooks: secondaryOrderBooks,
    onAfterSelectDefault: (item) => {
      setFormState((prevState) => ({
        ...prevState,
        secondary_order_book_id: String(item.id),
      }));
    },
    onAfterCreateDefault: () => {
      axios
        .get<OrderBooksListResponse>(`/api/order_books?instrument_id=${secondary_instrument_id}&bot_id=${currentBot?.id}`)
        .then((response) => {
          setSecondaryOrderBooks(response.data.data);
        });
    },
  });

  // Tertiary

  useReloadAccounts({
    mainExchange: tertiary_main_exchange,
    subExchange: tertiary_sub_exchange,
    selectedInstrument: selectedTertiaryInstrument,
    onDataReady: (items) => {
      setTertiaryAccounts(items);
    },
  });

  useReloadPrivateStreams({
    botId: currentBot?.id,
    instrumentId: tertiary_instrument_id,
    accountId: tertiary_account_id,
    selectedInstrument: selectedTertiaryInstrument,
    onDataReady: (items) => {
      setTertiaryPrivateStreams(items);
    },
  });

  useReloadContractStreams({
    botId: currentBot?.id,
    instrumentId: tertiary_instrument_id,
    accountId: tertiary_account_id,
    selectedInstrument: selectedTertiaryInstrument,
    onDataReady: (items) => {
      setTertiaryContractStreams(items);

      setFormState((prevState) => ({
        ...prevState,
        tertiary_contract_stream_id: '',
      }));
    },
  });

  useDefaultPrivateStream({
    botId: currentBot?.id,
    instrumentId: tertiary_instrument_id,
    accountId: tertiary_account_id,
    loadedInstruments: tertiaryInstruments,
    loadedPrivateStreams: tertiaryPrivateStreams,
    onAfterSelectDefault: (item) => {
      setFormState((prevState) => ({
        ...prevState,
        tertiary_private_stream_id: String(item.id),
      }));
    },
    onAfterCreateDefault: () => {
      axios
        .get<PrivateStreamsListResponse>(
          `/api/private_streams?instrument_id=${tertiary_instrument_id}&account_id=${tertiary_account_id}&bot_id=${currentBot?.id}`,
        )
        .then((response) => {
          let streams = response.data.data ? response.data.data : [];

          setTertiaryPrivateStreams(streams);
        });
    },
  });

  useDefaultContractStream({
    botId: currentBot?.id,
    instrumentId: tertiary_instrument_id,
    loadedInstruments: tertiaryInstruments,
    loadedContractStreams: tertiaryContractStreams,
    onAfterSelectDefault: (item) => {
      setFormState((prevState) => ({
        ...prevState,
        tertiary_contract_stream_id: String(item.id),
      }));
    },
    onAfterCreateDefault: () => {
      axios
        .get<ContractStreamsListResponse>(`/api/contract_streams?instrument_id=${tertiary_instrument_id}&bot_id=${currentBot?.id}`)
        .then((response) => {
          setTertiaryContractStreams(response.data.data);
        });
    },
  });

  useDefaultOrderBook({
    botId: currentBot?.id,
    instrumentId: tertiary_instrument_id,
    selectedInstrument: selectedTertiaryInstrument,
    loadedOrderBooks: tertiaryOrderBooks,
    onAfterSelectDefault: (item) => {
      setFormState((prevState) => ({
        ...prevState,
        tertiary_order_book_id: String(item.id),
      }));
    },
    onAfterCreateDefault: () => {
      axios
        .get<OrderBooksListResponse>(`/api/order_books?instrument_id=${tertiary_instrument_id}&bot_id=${currentBot?.id}`)
        .then((response) => {
          setTertiaryOrderBooks(response.data.data);
        });
    },
  });

  useEffect(() => {
    if (!primary_main_exchange || !primary_sub_exchange) return;

    fetchInstruments(primary_main_exchange, primary_sub_exchange, primaryInstrumentQuery, setPrimaryInstruments);
  }, [primaryInstrumentQuery]);

  useEffect(() => {
    if (!secondary_main_exchange || !secondary_sub_exchange) return;

    fetchInstruments(secondary_main_exchange, secondary_sub_exchange, secondaryInstrumentQuery, setSecondaryInstruments);
  }, [secondaryInstrumentQuery]);

  useEffect(() => {
    if (!tertiary_main_exchange || !tertiary_sub_exchange) return;

    fetchInstruments(tertiary_main_exchange, tertiary_sub_exchange, tertiaryInstrumentQuery, setTertiaryInstruments);
  }, [tertiaryInstrumentQuery]);

  const supportedCurrencies = useFilterSupportedTradingCurrencies({
    strategyType: strategy_type,
    primaryOrderBookId: primary_order_book_id,
    secondaryOrderBookId: secondary_order_book_id,
    selectedPrimaryInstrument: selectedPrimaryInstrument,
    selectedSecondaryInstrument: selectedSecondaryInstrument,
    primaryOrderBooks: primaryOrderBooks,
    secondaryOrderBooks: secondaryOrderBooks,
  });

  useEffect(() => {
    if (currency) return;

    // Set trading pair's currency to DEFAULT_CURRENCY which is USD when no currency is selected yet
    // and USD/USDT is supported by instruments on both sides
    if (supportedCurrencies.indexOf('USDT') > -1 || supportedCurrencies.indexOf('USD') > -1) {
      return setFormState((prevState) => ({
        ...prevState,
        currency: DEFAULT_CURRENCY,
      }));
    }

    // Otherwise, just pick the first supported currency
    setFormState((prevState) => ({
      ...prevState,
      currency: supportedCurrencies[0],
    }));
  }, [currency, supportedCurrencies]);

  return (
    <div>
      <div className="grid grid-cols-1 gap-4 new-pair-form">
        <FormGroup
          label="Strategy Type"
          labelFor="strategy_type"
          labelInfo="(required)"
          intent={validationError.has('strategy_type') ? Intent.DANGER : Intent.NONE}
          helperText={
            validationError.has('strategy_type') ? validationError.get('strategy_type') : 'Which strategy type you want to trade?'
          }
        >
          <HTMLSelect id="strategy_type" name="strategy_type" value={formState.strategy_type} onChange={handleInputChange}>
            <option value="">Choose an strategy type...</option>
            {supportedStrategyTypes.map((strategy, index) => (
              <option value={strategy.id} key={strategy.id}>
                {strategy.title}
              </option>
            ))}
          </HTMLSelect>
        </FormGroup>
      </div>

      <div className="grid grid-cols-3 gap-4 new-pair-form">
        <div>
          <h3 className="text-base font-bold my-1">Primary</h3>

          <FormGroup label="Exchange" labelInfo="(required)" className="new-pair-form">
            <ExchangeSelect
              mainExchange={formState.primary_main_exchange}
              subExchange={formState.primary_sub_exchange}
              handleSelect={(item: Exchange) => {
                if (primary_main_exchange !== item.main_exchange || primary_sub_exchange !== item.sub_exchange) {
                  const searchTerm = primaryInstrumentQuery
                    ? primaryInstrumentQuery
                    : formState.strategy_type === StrategyType.Normal
                    ? selectedSecondaryInstrument?.base_currency
                    : null;

                  fetchInstruments(item.main_exchange, item.sub_exchange, searchTerm, setPrimaryInstruments);

                  if (item.type === 'cex') {
                    axios
                      .get<AccountsListResponse>(`/api/accounts?main_exchange=${item.main_exchange}&sub_exchange=${item.sub_exchange}`)
                      .then((response) => {
                        setPrimaryAccounts(response.data.data);
                      });
                  }
                }

                setFormState((prevState) => ({
                  ...prevState,
                  primary_exchange_type: item.type,
                  primary_main_exchange: item.main_exchange,
                  primary_sub_exchange: item.sub_exchange,
                  primary_instrument_id: '',
                  primary_order_book_id: '',
                }));

                setPrimaryOrderBooks([]);
                setPrimaryInstrumentQuery('');
                setSelectedPrimaryInstrument(null);
              }}
            />
          </FormGroup>

          <FormGroup label="Instrument" labelInfo="(required)" className="new-pair-form">
            <InstrumentSelect
              selectedItem={selectedPrimaryInstrument}
              items={primaryInstruments}
              disabled={!primary_main_exchange || !primary_sub_exchange}
              handleSelect={handleSelectPrimaryInstrument}
              query={primaryInstrumentQuery}
              handleQueryChange={(v) => {
                setPrimaryInstrumentQuery(v);
              }}
            />
          </FormGroup>

          <FormGroup label="Account" labelFor="primary_account_id" labelInfo="(required)" helperText="" className="new-pair-form">
            <HTMLSelect
              disabled={primaryAccounts.length < 1}
              id="primary_account_id"
              name="primary_account_id"
              value={formState.primary_account_id}
              onChange={handleInputChange}
            >
              <option value="">Choose an account...</option>
              {primaryAccounts.map(({ id, name }) => (
                <option value={id} key={id}>
                  {name}
                </option>
              ))}
            </HTMLSelect>
          </FormGroup>

          <p className="font-normal text-xs cursor-pointer">
            <a className="text-blue-500 " onClick={() => setShowPrimaryAdvancedSettings(!showPrimaryAdvancedSettings)}>
              ({showPrimaryAdvancedSettings ? 'Hide' : 'Show'} advanced settings)
            </a>
          </p>

          <Collapse isOpen={showPrimaryAdvancedSettings}>
            {(selectedPrimaryInstrument?.exchange_type === ExchangeType.CEX || selectedPrimaryInstrument?.main_exchange === 'blur') && (
              <FormGroup label="OrderBook" labelFor="primary_order_book_id" labelInfo="(required)" helperText="">
                <OrderBookSelect
                  selectedItemId={formState.primary_order_book_id}
                  items={primaryOrderBooks}
                  disabled={primaryOrderBooks.length < 1}
                  handleSelect={(item: OrderBook) => {
                    setFormState((prevState) => ({
                      ...prevState,
                      primary_order_book_id: String(item.id),
                    }));
                  }}
                />
              </FormGroup>
            )}

            {(selectedPrimaryInstrument?.exchange_type === ExchangeType.CEX ||
              (selectedPrimaryInstrument?.exchange_type === ExchangeType.DEX && selectedPrimaryInstrument?.main_exchange === 'blur')) && (
              <FormGroup label="Private Stream" labelFor="primary_private_stream_id" labelInfo="(required)" helperText="">
                <PrivateStreamSelect
                  selectedItemId={formState.primary_private_stream_id}
                  items={primaryPrivateStreams}
                  disabled={primaryPrivateStreams.length < 1}
                  handleSelect={(item: PrivateStream) => {
                    setFormState((prevState) => ({
                      ...prevState,
                      primary_private_stream_id: String(item.id),
                    }));
                  }}
                />
              </FormGroup>
            )}

            {selectedPrimaryInstrument?.exchange_type === ExchangeType.DEX && selectedPrimaryInstrument?.main_exchange !== 'blur' && (
              <FormGroup label="Contract Stream" labelFor="primary_contract_stream_id" labelInfo="(required)" helperText="">
                <ContractStreamSelect
                  selectedItemId={formState.primary_contract_stream_id}
                  items={primaryContractStreams}
                  disabled={primaryContractStreams.length < 1}
                  handleSelect={(item: ContractStream) => {
                    setFormState((prevState) => ({
                      ...prevState,
                      primary_contract_stream_id: String(item.id),
                    }));
                  }}
                />
              </FormGroup>
            )}
          </Collapse>
        </div>

        <div>
          <h3 className="text-base font-bold my-1">Secondary</h3>

          <FormGroup label="Exchange" labelInfo="(required)" className="new-pair-form">
            <ExchangeSelect
              mainExchange={formState.secondary_main_exchange}
              subExchange={formState.secondary_sub_exchange}
              handleSelect={(item: Exchange) => {
                if (secondary_main_exchange !== item.main_exchange || secondary_sub_exchange !== item.sub_exchange) {
                  const searchTerm = secondaryInstrumentQuery
                    ? secondaryInstrumentQuery
                    : formState.strategy_type === StrategyType.Normal
                    ? selectedPrimaryInstrument?.base_currency
                    : null;

                  fetchInstruments(item.main_exchange, item.sub_exchange, searchTerm, setSecondaryInstruments);

                  axios
                    .get<AccountsListResponse>(`/api/accounts?main_exchange=${item.main_exchange}&sub_exchange=${item.sub_exchange}`)
                    .then((response) => {
                      setSecondaryAccounts(response.data.data);
                    });
                }

                setFormState((prevState) => ({
                  ...prevState,
                  secondary_main_exchange: item.main_exchange,
                  secondary_sub_exchange: item.sub_exchange,
                  secondary_instrument_id: '',
                  secondary_order_book_id: '',
                }));

                setSecondaryOrderBooks([]);
                setSecondaryInstrumentQuery('');
                setSelectedSecondaryInstrument(null);
              }}
            />
          </FormGroup>

          <FormGroup label="Instrument" labelInfo="(required)" className="new-pair-form">
            <InstrumentSelect
              selectedItem={selectedSecondaryInstrument}
              items={secondaryInstruments}
              disabled={!secondary_main_exchange || !secondary_sub_exchange}
              handleSelect={handleSelectSecondaryInstrument}
              query={secondaryInstrumentQuery}
              handleQueryChange={setSecondaryInstrumentQuery}
            />
          </FormGroup>

          <FormGroup label="Account" labelFor="secondary_account_id" labelInfo="(required)" helperText="" className="new-pair-form">
            <HTMLSelect
              disabled={secondaryAccounts.length < 1}
              id="secondary_account_id"
              name="secondary_account_id"
              value={formState.secondary_account_id}
              onChange={handleInputChange}
            >
              <option value="">Choose an account...</option>
              {secondaryAccounts.map(({ id, name }) => (
                <option value={id} key={id}>
                  {name}
                </option>
              ))}
            </HTMLSelect>
          </FormGroup>

          <p className="font-normal text-xs cursor-pointer">
            <a className="text-blue-500 " onClick={() => setShowSecondaryAdvancedSettings(!showSecondaryAdvancedSettings)}>
              ({showSecondaryAdvancedSettings ? 'Hide' : 'Show'} advanced settings)
            </a>
          </p>

          <Collapse isOpen={showSecondaryAdvancedSettings}>
            {selectedSecondaryInstrument?.exchange_type === ExchangeType.CEX && (
              <FormGroup label="OrderBook" labelFor="secondary_order_book_id" labelInfo="(required)" helperText="">
                <OrderBookSelect
                  selectedItemId={formState.secondary_order_book_id}
                  items={secondaryOrderBooks}
                  disabled={secondaryOrderBooks.length < 1}
                  handleSelect={(item: OrderBook) => {
                    setFormState((prevState) => ({
                      ...prevState,
                      secondary_order_book_id: String(item.id),
                    }));
                  }}
                />
              </FormGroup>
            )}

            {(selectedSecondaryInstrument?.exchange_type === ExchangeType.CEX ||
              (selectedSecondaryInstrument?.exchange_type === ExchangeType.DEX &&
                selectedSecondaryInstrument?.main_exchange === 'blur')) && (
              <FormGroup label="Private Stream" labelFor="secondary_private_stream_id" labelInfo="(required)" helperText="">
                <PrivateStreamSelect
                  selectedItemId={formState.secondary_private_stream_id}
                  items={secondaryPrivateStreams}
                  disabled={secondaryPrivateStreams.length < 1}
                  handleSelect={(item: PrivateStream) => {
                    setFormState((prevState) => ({
                      ...prevState,
                      secondary_private_stream_id: String(item.id),
                    }));
                  }}
                />
              </FormGroup>
            )}

            {selectedSecondaryInstrument?.exchange_type === ExchangeType.DEX && selectedSecondaryInstrument?.main_exchange !== 'blur' && (
              <FormGroup label="Contract Stream" labelFor="secondary_contract_stream_id" labelInfo="(required)" helperText="">
                <ContractStreamSelect
                  selectedItemId={formState.secondary_contract_stream_id}
                  items={secondaryContractStreams}
                  disabled={secondaryContractStreams.length < 1}
                  handleSelect={(item: ContractStream) => {
                    setFormState((prevState) => ({
                      ...prevState,
                      secondary_contract_stream_id: String(item.id),
                    }));
                  }}
                />
              </FormGroup>
            )}
          </Collapse>
        </div>

        <div className="grid grid-cols-1 gap-4 new-pair-form">
          {formState.strategy_type === StrategyType.NFTMarketMaking && (
            <div>
              <h3 className="text-base font-bold my-1">Tertiary</h3>

              <FormGroup label="Exchange" labelInfo="(required)" className="new-pair-form">
                <ExchangeSelect
                  mainExchange={formState.tertiary_main_exchange}
                  subExchange={formState.tertiary_sub_exchange}
                  handleSelect={(item: Exchange) => {
                    if (tertiary_main_exchange !== item.main_exchange || tertiary_sub_exchange !== item.sub_exchange) {
                      const searchTerm = tertiaryInstrumentQuery
                        ? tertiaryInstrumentQuery
                        : formState.strategy_type === StrategyType.Normal
                        ? selectedPrimaryInstrument?.base_currency
                        : null;

                      fetchInstruments(item.main_exchange, item.sub_exchange, searchTerm, setTertiaryInstruments);

                      axios
                        .get<AccountsListResponse>(`/api/accounts?main_exchange=${item.main_exchange}&sub_exchange=${item.sub_exchange}`)
                        .then((response) => {
                          setTertiaryAccounts(response.data.data);
                        });
                    }

                    setFormState((prevState) => ({
                      ...prevState,
                      tertiary_main_exchange: item.main_exchange,
                      tertiary_sub_exchange: item.sub_exchange,
                      tertiary_instrument_id: '',
                      tertiary_order_book_id: '',
                    }));

                    setTertiaryOrderBooks([]);
                    setTertiaryInstrumentQuery('');
                    setSelectedTertiaryInstrument(null);
                  }}
                />
              </FormGroup>

              <FormGroup label="Instrument" labelInfo="(required)" className="new-pair-form">
                <InstrumentSelect
                  selectedItem={selectedTertiaryInstrument}
                  items={tertiaryInstruments}
                  disabled={!tertiary_main_exchange || !tertiary_sub_exchange}
                  handleSelect={handleSelectTertiaryInstrument}
                  query={tertiaryInstrumentQuery}
                  handleQueryChange={setTertiaryInstrumentQuery}
                />
              </FormGroup>

              <FormGroup label="Account" labelFor="tertiary_account_id" labelInfo="(required)" helperText="" className="new-pair-form">
                <HTMLSelect
                  disabled={tertiaryAccounts.length < 1}
                  id="tertiary_account_id"
                  name="tertiary_account_id"
                  value={formState.tertiary_account_id}
                  onChange={handleInputChange}
                >
                  <option value="">Choose an account...</option>
                  {tertiaryAccounts.map(({ id, name }) => (
                    <option value={id} key={id}>
                      {name}
                    </option>
                  ))}
                </HTMLSelect>
              </FormGroup>

              <p className="font-normal text-xs cursor-pointer">
                <a className="text-blue-500 " onClick={() => setShowTertiaryAdvancedSettings(!showTertiaryAdvancedSettings)}>
                  ({showTertiaryAdvancedSettings ? 'Hide' : 'Show'} advanced settings)
                </a>
              </p>

              <Collapse isOpen={showTertiaryAdvancedSettings}>
                {selectedSecondaryInstrument?.exchange_type === ExchangeType.CEX && (
                  <FormGroup label="OrderBook" labelFor="tertiary_order_book_id" labelInfo="(required)" helperText="">
                    <OrderBookSelect
                      selectedItemId={formState.tertiary_order_book_id}
                      items={tertiaryOrderBooks}
                      disabled={tertiaryOrderBooks.length < 1}
                      handleSelect={(item: OrderBook) => {
                        setFormState((prevState) => ({
                          ...prevState,
                          tertiary_order_book_id: String(item.id),
                        }));
                      }}
                    />
                  </FormGroup>
                )}

                {(selectedTertiaryInstrument?.exchange_type === ExchangeType.CEX ||
                  (selectedTertiaryInstrument?.exchange_type === ExchangeType.DEX &&
                    selectedTertiaryInstrument?.main_exchange === 'blur')) && (
                  <FormGroup label="Private Stream" labelFor="tertiary_private_stream_id" labelInfo="(required)" helperText="">
                    <PrivateStreamSelect
                      selectedItemId={formState.tertiary_private_stream_id}
                      items={tertiaryPrivateStreams}
                      disabled={tertiaryPrivateStreams.length < 1}
                      handleSelect={(item: PrivateStream) => {
                        setFormState((prevState) => ({
                          ...prevState,
                          tertiary_private_stream_id: String(item.id),
                        }));
                      }}
                    />
                  </FormGroup>
                )}

                {selectedTertiaryInstrument?.exchange_type === ExchangeType.DEX && selectedTertiaryInstrument?.main_exchange !== 'blur' && (
                  <FormGroup label="Contract Stream" labelFor="tertiary_contract_stream_id" labelInfo="(required)" helperText="">
                    <ContractStreamSelect
                      selectedItemId={formState.tertiary_contract_stream_id}
                      items={tertiaryContractStreams}
                      disabled={tertiaryContractStreams.length < 1}
                      handleSelect={(item: ContractStream) => {
                        setFormState((prevState) => ({
                          ...prevState,
                          tertiary_contract_stream_id: String(item.id),
                        }));
                      }}
                    />
                  </FormGroup>
                )}
              </Collapse>
            </div>
          )}
        </div>
      </div>

      <div>
        <h3 className="text-base font-bold my-1">Settings</h3>

        <div className="grid grid-cols-2 gap-4">
          <div className="mt-5">
            <FormGroup
              label="Currency"
              labelFor="currency"
              labelInfo="(required)"
              helperText="Which currency you want to use for Quantity, Offset and Max Individual settings?"
            >
              <HTMLSelect
                disabled={supportedCurrencies.length < 1}
                id="currency"
                name="currency"
                value={formState.currency}
                onChange={handleInputChange}
              >
                <option value="">Choose an currency...</option>
                {supportedCurrencies.map((currency, index) => (
                  <option value={currency} key={index}>
                    {currency}
                  </option>
                ))}
              </HTMLSelect>
            </FormGroup>

            {selectedPrimaryInstrument?.exchange_type === ExchangeType.DEX && strategy_type !== StrategyType.NFTMarketMaking && (
              <FormGroup label="DeFi Quota" labelFor="defi_quota" labelInfo="(required)" helperText="Quota for DeFi trade">
                <InputGroup
                  id="defi_quota"
                  name="defi_quota"
                  value={formState.defi_quota}
                  onChange={handleInputChange}
                  placeholder="E.g. 5"
                />
              </FormGroup>
            )}

            {selectedPrimaryInstrument?.exchange_type === ExchangeType.DEX && strategy_type !== StrategyType.NFTMarketMaking && (
              <FormGroup
                label="DeFi Replenish Delay"
                labelFor="defi_replenish_delay"
                labelInfo="(required)"
                helperText="Replenish delay for DeFi trade"
              >
                <InputGroup
                  id="defi_replenish_delay"
                  name="defi_replenish_delay"
                  value={formState.defi_replenish_delay}
                  onChange={handleInputChange}
                  placeholder="E.g. 5000"
                />
              </FormGroup>
            )}
          </div>
          <div className="mt-5">
            <h3 className="text-base font-bold my-1">&nbsp;</h3>
            <FormGroup
              label="Default debounce"
              labelFor="default_debounce"
              labelInfo="(required)"
              helperText="Default debounce for opportunies to submit/amend/cancel orders (defaults to 5000)"
            >
              <NumericInput
                id="default_debounce"
                name="default_debounce"
                value={formState.default_debounce?.toLocaleString('en-US', { maximumFractionDigits: 10 })}
                onValueChange={handleNumberValueChange}
                buttonPosition="none"
                placeholder="E.g. 60000"
              />
            </FormGroup>
          </div>
        </div>

        {/* <div className="grid grid-cols-1 gap-4">
          <div className="mt-5">
            <FormGroup
              label="Hedge Bot"
              labelFor="hedge_bot"
              labelInfo="(required)"
              helperText="Which Spread bot you want to use to hedge for this pair?"
            >
              <BotSelect
                selectedBotId={formState.hedge_bot_id}
                handleSelect={(item: Bot) => {
                  setFormState((prevState) => ({
                    ...prevState,
                    hedge_bot_id: item.id || currentBot?.id,
                  }));
                }}
              />
            </FormGroup>

            {currentBot?.id === formState.hedge_bot_id && <p className="font-bold">NOTE: This bot will hedge by itself</p>}

            {currentBot?.id !== formState.hedge_bot_id && (
              <p className="font-bold">NOTE: This bot will hedge by the bot above instead of by itself</p>
            )}
          </div>
        </div> */}

        <div className="grid grid-cols-1">
          <FormGroup label="Description" labelFor="description">
            <TextArea
              id="description"
              name="description"
              fill={true}
              value={formState.description}
              onChange={handleInputChange}
              placeholder=""
            />
          </FormGroup>
        </div>
      </div>

      <div className="mt-7 text-center">
        {formState.id && (
          <Button intent="primary" large={true} disabled={validationError.size > 0} onClick={handleUpdate}>
            Update
          </Button>
        )}

        {!formState.id && (
          <Button intent="primary" large={true} disabled={validationError.size > 0} loading={isProcessing} onClick={handleCreate}>
            Create
          </Button>
        )}
      </div>
    </div>
  );
}
