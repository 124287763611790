import { FormEvent, useContext, useEffect, useState } from 'react';
import axios from '../../../shared/custom-axios';
import {
  Button,
  Card,
  Classes,
  ControlGroup,
  Drawer,
  FormGroup,
  H5,
  HTMLSelect,
  Icon,
  InputGroup,
  Intent,
  Popover,
  PopoverPosition,
  Tag,
} from '@blueprintjs/core';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import { AppToaster } from '../../../shared/app-toaster';
import { StartStopButton } from '../../common/button/start_stop_button';
import {
  OrderBook,
  OrderBookResponse,
  OrderBooksListResponse,
  PriceLevel,
  SnapshotOrderBookResponse,
} from '../../../shared/interfaces/bot';
import { BotsManagerContext } from '../../../contexts/bots-manager';

import { OrderBookVisualizer } from '../../../components/order-book/visualizer/visualizer';
import { getDepths } from '../../instrument/utils';

export function OrderBookList() {
  const { currentBot } = useContext(BotsManagerContext);
  const [orderBooks, setOrderBooks] = useState<OrderBook[]>([]);
  const [selectedOrderBook, setSelectedOrderBook] = useState<OrderBook | null>(null);
  const [priceLevelsNumber, setPriceLevelsNumber] = useState('10');
  const [tradingPairId, setTradingPairId] = useState('');
  const [opportunityId, setOpportunityId] = useState('');
  const [sellPriceLevels, setSellPriceLevels] = useState<PriceLevel[]>([]);
  const [buyPriceLevels, setBuyPriceLevels] = useState<PriceLevel[]>([]);
  const [impliedPrice, setImpliedPrice] = useState<number | null>(null);
  const [currentOrder, setCurrentOrder] = useState<number | null>(null);
  const [orderBookGrouping, setOrderBookGrouping] = useState('');
  const [orderBookGroupings, setOrderBookGroupings] = useState<number[]>([]);

  const [ratioSettings, setRatioSettings] = useState<any>(null);

  useEffect(() => {
    // Empty the showing order book when drawer on the right side is closed
    if (!selectedOrderBook) {
      setSellPriceLevels([]);
      setBuyPriceLevels([]);

      return;
    }

    const depths = getDepths(selectedOrderBook.instrument);
    setOrderBookGroupings(depths);
  }, [selectedOrderBook]);

  useEffect(() => {
    if (!currentBot) {
      return;
    }

    axios.get<OrderBooksListResponse>(`/api/order_books?bot_id=${currentBot?.id}`).then((response) => {
      setOrderBooks(response.data.data);
    });
  }, [currentBot]);

  const handleDelete = (orderBookId: number) => {
    axios
      .delete<OrderBooksListResponse>(`/api/order_books/${orderBookId}`)
      .then(() => {
        setOrderBooks((prevState) => {
          const newState = prevState.filter(({ id }) => id !== orderBookId);
          return newState;
        });

        const message = 'Order Book deleted successfully!';
        AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 2000 });
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      });
  };

  const handleStart = (orderBookId: number) => {
    axios
      .put<OrderBookResponse>(`/api/order_books/${orderBookId}/start`)
      .then((response) => {
        const newData = response.data.data;

        setOrderBooks((prevState) => {
          return prevState.map((orderBook) => {
            if (orderBook.id === newData.id) {
              return newData;
            } else {
              return orderBook;
            }
          });
        });

        if (selectedOrderBook?.id === newData.id) {
          setSelectedOrderBook(newData);
        }

        const message = 'Order Book started';
        AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 2000 });
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      });
  };

  const handleStop = (orderBookId: number) => {
    axios
      .put<OrderBookResponse>(`/api/order_books/${orderBookId}/stop`)
      .then((response) => {
        const newData = response.data.data;

        setOrderBooks((prevState) => {
          return prevState.map((orderBook) => {
            if (orderBook.id === newData.id) {
              return newData;
            } else {
              return orderBook;
            }
          });
        });

        if (selectedOrderBook?.id === newData.id) {
          setSelectedOrderBook(newData);
        }

        const message = 'Order Book stopped';
        AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 2000 });
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      });
  };

  const handleGetSnapshot = (orderBookId: number) => {
    let apiUrl = `/api/order_books/${orderBookId}/snapshot?limit=${priceLevelsNumber}&grouping=${orderBookGrouping}`;

    if (tradingPairId !== '') {
      apiUrl = `${apiUrl}&trading_pair_id=${tradingPairId}`;
    }

    if (opportunityId !== '') {
      apiUrl = `${apiUrl}&opportunity_id=${opportunityId}`;
    }

    axios
      .get<SnapshotOrderBookResponse>(apiUrl)
      .then((response) => {
        console.log(response.data);
        setSellPriceLevels(response.data.order_book.sell.reverse());
        setBuyPriceLevels(response.data.order_book.buy);
        setImpliedPrice(response.data.implied_price);
        if (response.data.order) {
          setCurrentOrder(response.data.order.amount);
        } else {
          setCurrentOrder(null);
        }

        if (response.data.activate_ratio_distance) {
          setRatioSettings({
            activate_ratio_distance: response.data.activate_ratio_distance,
            ratio_tick_size: response.data.ratio_tick_size,
            implied_volume: response.data.implied_volume,
          });
        } else {
          setRatioSettings(null);
        }

        const message = 'Fetched order book successfully';
        AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 500 });
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 2000 });
      });
  };

  const handleOrderBookGroupingChange = (event: FormEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { value } = event.currentTarget;
    setOrderBookGrouping(value);
  };

  return (
    <>
      <h2 className="text-lg font-bold my-1">Order Books</h2>
      <Link to={`/bots/${currentBot?.app_id}/order_books/new`} className="mt-1 bp4-button">
        <Icon icon="add" />
        <span>New</span>
      </Link>

      <div className="order-books grid gap-10 grid-cols-4 mt-5">
        {orderBooks.map((orderBook) => {
          return (
            <Card interactive={true} elevation={2} key={orderBook.id} className="group">
              <div className="order-book">
                <div
                  className="order-book-header pt-3 relative"
                  onClick={() => {
                    setSelectedOrderBook(orderBook);
                  }}
                >
                  <H5>
                    {orderBook.name}
                    <span className="text-gray-300 select-none"> #{orderBook.id}</span>
                  </H5>

                  {orderBook.is_default && (
                    <span className="absolute -top-5 -left-5">
                      <Tag minimal={true}>Default</Tag>
                    </span>
                  )}
                </div>

                <div
                  className="order-book-body"
                  onClick={() => {
                    setSelectedOrderBook(orderBook);
                  }}
                >
                  <p>{orderBook.description}</p>
                  <p>
                    Exchange: {_.upperFirst(orderBook.instrument.main_exchange)} {_.upperFirst(orderBook.instrument.sub_exchange)}
                  </p>
                  <p>Instrument: {orderBook.instrument.symbol}</p>
                </div>

                <div className="order-book-footer text-right">
                  <StartStopButton item={orderBook} handleStart={handleStart} handleStop={handleStop} />
                </div>
              </div>
            </Card>
          );
        })}

        <Drawer
          title={`${selectedOrderBook?.id}. ${selectedOrderBook?.name}`}
          size="500px"
          isOpen={!!selectedOrderBook}
          onClose={() => {
            setSelectedOrderBook(null);
          }}
        >
          {selectedOrderBook && (
            <div className={Classes.DRAWER_BODY}>
              <div className={Classes.DIALOG_BODY}>
                <p>Description: {selectedOrderBook.description}</p>
                <p>
                  Exchange: {_.upperFirst(selectedOrderBook.instrument.main_exchange)}{' '}
                  {_.upperFirst(selectedOrderBook.instrument.sub_exchange)}
                </p>
                <p>Artificial Depth: {selectedOrderBook.artificial_depth}</p>
                <p>Artificial Depth Distance: {selectedOrderBook.artificial_depth_distance}</p>
                {ratioSettings ? (
                  <div className="text-blue-500 font-bold">
                    <p>Activate Ratio Distance: {ratioSettings.activate_ratio_distance} bips</p>
                    <p>Tick Size: {ratioSettings.ratio_tick_size}</p>
                    <p>Implied Volume: {ratioSettings.implied_volume}</p>
                    <p>Implied Price: {impliedPrice}</p>
                  </div>
                ) : null}
                <p>
                  <StartStopButton item={selectedOrderBook} handleStart={handleStart} handleStop={handleStop} />
                  <FormGroup helperText={`Show ${priceLevelsNumber} price levels on each side (buy/sell)`}>
                    <ControlGroup fill={true} className="mt-2 text-right">
                      <InputGroup
                        placeholder="Trading pair ID"
                        value={tradingPairId}
                        onChange={(e: FormEvent<HTMLInputElement>) => setTradingPairId(e.currentTarget.value)}
                      />
                      <InputGroup
                        placeholder="Opp ID"
                        value={opportunityId}
                        onChange={(e: FormEvent<HTMLInputElement>) => setOpportunityId(e.currentTarget.value)}
                      />
                    </ControlGroup>
                  </FormGroup>
                </p>

                <div className="mt-8">
                  <FormGroup helperText={`Show ${priceLevelsNumber} price levels on each side (buy/sell)`}>
                    <ControlGroup fill={true} className="mt-2 text-right">
                      <HTMLSelect
                        id="main_exchange"
                        name="main_exchange"
                        value={orderBookGrouping}
                        onChange={handleOrderBookGroupingChange}
                      >
                        {orderBookGroupings.map((grouping, index) => (
                          <option value={grouping} key={index}>
                            {grouping}
                          </option>
                        ))}
                      </HTMLSelect>
                      <InputGroup
                        placeholder="Number of price levels..."
                        value={priceLevelsNumber}
                        onChange={(e: FormEvent<HTMLInputElement>) => setPriceLevelsNumber(e.currentTarget.value)}
                      />
                      <Button icon="arrow-down" onClick={() => handleGetSnapshot(selectedOrderBook.id)}>
                        Get
                      </Button>
                    </ControlGroup>
                  </FormGroup>

                  <OrderBookVisualizer
                    sell={sellPriceLevels}
                    buy={buyPriceLevels}
                    instrument={selectedOrderBook.instrument}
                    impliedPrice={impliedPrice}
                    currentOrder={currentOrder}
                  />
                </div>
              </div>
            </div>
          )}
          <div className={Classes.DRAWER_FOOTER}>
            {selectedOrderBook && (
              <Popover popoverClassName={Classes.POPOVER_CONTENT_SIZING} position={PopoverPosition.RIGHT}>
                <Button intent={Intent.DANGER} text="Delete" small={true} />
                <div key="text">
                  <H5>Confirm deletion</H5>
                  <p>Are you sure you want to delete this account?</p>
                  <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
                    <Button className={Classes.POPOVER_DISMISS} style={{ marginRight: 10 }}>
                      Cancel
                    </Button>
                    <Button onClick={() => handleDelete(selectedOrderBook.id)} intent={Intent.DANGER} className={Classes.POPOVER_DISMISS}>
                      Delete
                    </Button>
                  </div>
                </div>
              </Popover>
            )}
          </div>
        </Drawer>
      </div>
    </>
  );
}
