import { createContext, useState } from 'react';

interface UnderContructionState {
  isUnderConstruction: boolean;
  setUnderConstruction?: (isOpen: boolean) => void;
}

export const UnderContructionContext = createContext<UnderContructionState>({ isUnderConstruction: false });

export function UnderContructionContextProvider({ children }: any) {
  const [isUnderConstruction, setUnderConstruction] = useState<boolean>(false);

  return (
    <UnderContructionContext.Provider value={{ isUnderConstruction, setUnderConstruction }}>{children}</UnderContructionContext.Provider>
  );
}
