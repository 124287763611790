import { createContext, useEffect, useState } from 'react';

import { User, UserResponse } from '../shared/interfaces/bot';
import axios from '../shared/custom-axios';

export interface Auth {
  token: string | null;
  setToken?(value: string | null): void;
  currentUser: User | null;
  setCurrentUser?(user: User | null): void;
}

const defaultState: Auth = {
  currentUser: null,
  token: null,
};

export const AuthContext = createContext<Auth>(defaultState);

const STORAGE_KEY = 'user_token';

const getToken = () => {
  const userToken = localStorage.getItem(STORAGE_KEY);

  return userToken;
};

export function AuthContextProvider({ children }: any) {
  const [token, setToken] = useState<string | null>(getToken());
  const [currentUser, setCurrentUser] = useState<User | null>(null);

  useEffect(() => {
    localStorage.setItem(STORAGE_KEY, token || '');
  }, [token]);

  useEffect(() => {
    if (!token) {
      return;
    }

    axios.get<UserResponse>('/auth/user').then((response) => {
      if (setCurrentUser) {
        setCurrentUser(response.data.data);
      }
    });
  }, [token]);

  return <AuthContext.Provider value={{ token, setToken, currentUser, setCurrentUser }}>{children}</AuthContext.Provider>;
}
