import { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { Button, MenuItem } from '@blueprintjs/core';
import { ItemPredicate, ItemRenderer, Select } from '@blueprintjs/select';

import { Exchange, ExchangeType } from '../../../shared/interfaces/bot';
import { ExchangeInfo, ExchangesInfoContext } from '../../../contexts/exchanges-info';

interface Props {
  mainExchange: string;
  subExchange: string;
  handleSelect: (item: Exchange) => void;
  hasAllOption?: boolean;
  mode?: 'full' | 'short';
}

const InnerExchangeSelect = Select.ofType<Exchange>();

const renderItem: ItemRenderer<Exchange> = (item, { handleClick, modifiers }) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }

  return <MenuItem active={modifiers.active} key={item.name} onClick={handleClick} text={item.name} />;
};

const filterItem: ItemPredicate<Exchange> = (query, exchange) => {
  return `${exchange.main_exchange}. ${exchange.sub_exchange.toLowerCase()}`.indexOf(query.toLowerCase()) >= 0;
};

export function ExchangeSelect({ mainExchange, subExchange, handleSelect, hasAllOption = false, mode = 'full' }: Props) {
  const supportedExchanges = useContext(ExchangesInfoContext);
  const [items, setItems] = useState<Exchange[]>([]);
  const [selectedItem, setSelectedItem] = useState<Exchange | null>(null);

  useEffect(() => {
    let items = mode === 'full' ? buildExchangeFullList() : buildExchangeShortList();

    if (hasAllOption) {
      items = _.concat(
        [
          {
            name: 'All exchange',
            type: ExchangeType.CEX,
            main_exchange: '',
            sub_exchange: '',
          },
        ],
        items,
      );
    }

    setItems(items);
  }, [supportedExchanges, hasAllOption]);

  useEffect(() => {
    if (items.length < 1) {
      setSelectedItem(null);

      return;
    }

    const selectedItem = getSelectedItem(items, mainExchange, subExchange);

    setSelectedItem(selectedItem || null);
  }, [items, mainExchange, subExchange]);

  const getSelectedItem = (items: Exchange[], mainExchange: string, subExchange: string) => {
    if (mode === 'short') {
      return !mainExchange ? null : items.find((item: Exchange) => item.main_exchange === mainExchange);
    } else {
      return !mainExchange || !subExchange
        ? null
        : items.find((item: Exchange) => item.main_exchange === mainExchange && item.sub_exchange === subExchange);
    }
  };

  const buildExchangeFullList = () => {
    return _.flatMap(supportedExchanges, (supportedExchange: ExchangeInfo) => {
      return supportedExchange.sub_exchanges.map((subExchange) => {
        let name = `${_.upperFirst(supportedExchange.name)}`;

        if (supportedExchange.type === 'cex') {
          name = `${_.upperFirst(supportedExchange.name)} ${_.upperFirst(subExchange.name)}`;
        }

        return {
          name: name,
          type: supportedExchange.type,
          main_exchange: supportedExchange.id,
          sub_exchange: subExchange.id,
        };
      });
    });
  };

  const buildExchangeShortList = () => {
    return supportedExchanges.map((exchange: ExchangeInfo) => {
      return {
        name: exchange.name,
        type: exchange.type,
        main_exchange: exchange.id,
        sub_exchange: '',
      };
    });
  };

  return (
    <>
      <InnerExchangeSelect
        itemRenderer={renderItem}
        itemPredicate={filterItem}
        onItemSelect={handleSelect}
        activeItem={selectedItem}
        items={items}
      >
        {selectedItem && <Button text={selectedItem.name} rightIcon="double-caret-vertical" />}

        {!selectedItem && <Button text={hasAllOption ? 'All exchange' : 'Choose an exchange'} rightIcon="double-caret-vertical" />}
      </InnerExchangeSelect>
    </>
  );
}
