import { forwardRef, memo } from 'react';
import HotTable from '@handsontable/react';
import Handsontable from 'handsontable';

interface Props {
  settings: Handsontable.GridSettings;
  ref: React.MutableRefObject<HotTable | null>;
}

export const OpportunitiesEditorTable = memo(
  forwardRef<any, Props>(({ settings }: Props, ref) => {
    return <HotTable settings={settings} ref={ref} />;
  }),
);
