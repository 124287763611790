import { createContext, useState } from 'react';

import { BinanceRateLimit } from '../shared/interfaces/bot';

export interface RateLimitInfoStore {
  accountRateLimits: BinanceRateLimit[];
  setAccountRateLimits?(items: BinanceRateLimit[]): void;
}

const defaultState: RateLimitInfoStore = {
  accountRateLimits: [],
};

export const RateLimitContext = createContext<RateLimitInfoStore>(defaultState);

export function RateLimitContextProvider({ children }: any) {
  const [accountRateLimits, setAccountRateLimits] = useState<Array<BinanceRateLimit>>([]);

  return <RateLimitContext.Provider value={{ accountRateLimits, setAccountRateLimits }}>{children}</RateLimitContext.Provider>;
}
