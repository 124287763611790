import { createContext, useEffect, useRef, useState } from 'react';
import { Channel } from 'phoenix';

import { Bot } from '../shared/interfaces/bot';
import socket from '../socket';

export interface BotsManager {
  bots: Bot[];
  currentBot?: Bot | null;
  setCurrentBot?(bot: Bot | null): void;
}

const defaultState: BotsManager = {
  bots: [],
  currentBot: null,
};

export const BotsManagerContext = createContext<BotsManager>(defaultState);

export function BotsManagerContextProvider({ children }: any) {
  const [bots, setBots] = useState<Array<Bot>>([]);
  const [currentBot, setCurrentBot] = useState<Bot | null>(null);

  let channel = useRef<Channel | null>(null);

  useEffect(() => {
    channel.current = socket.channel('bot:lobby', { from: 'BotsManagerContextProvider' });

    channel.current
      ?.join()
      .receive('ok', () => {
        console.log(`[BotsManagerContextProvider] Joined "bot:lobby" channel for bots real-time updates`);
      })
      .receive('error', (resp) => {
        console.log('[BotsManagerContextProvider] Cannot join "bot:lobby" for bots real-time updates', resp);
      });

    channel.current?.onClose(() => {
      console.log('[BotsManagerContextProvider] Left "bot:lobby"');
    });

    channel.current
      ?.push('list_bots', {})
      .receive('ok', (payload) => {
        let bots: Bot[] = payload.data;
        bots.sort((a, b) => a.name.localeCompare(b.name));

        setBots(bots);
      })
      .receive('timeout', () => {
        alert('Timeout! Please try to reload the page.');
      });

    channel.current?.on('bots_updates', (data) => {
      setBots(data.data);
    });

    return () => {
      // Make sure we leave the current WS channels when switching to another bot
      channel.current?.leave();
    };
  }, []);

  return <BotsManagerContext.Provider value={{ bots, currentBot, setCurrentBot }}>{children}</BotsManagerContext.Provider>;
}
