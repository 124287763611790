import { createContext, useContext, useEffect, useRef, useState } from 'react';

import { RestRequestUsage } from '../shared/interfaces/bot';
import { Channel } from 'phoenix';
import socket from '../socket';
import { BotsManagerContext } from './bots-manager';

export interface State {
  restRequestUsage: { [key: string]: RestRequestUsage | undefined };
}

const defaultState: State = {
  restRequestUsage: {},
};

export const RestRequestTrackerContext = createContext<State>(defaultState);

export function RestRequestTrackerContextProvider({ children }: any) {
  const { currentBot } = useContext(BotsManagerContext);

  let channel = useRef<Channel | null>(null);

  const [usage, setUsage] = useState<{
    [key: string]: RestRequestUsage;
  }>({});

  useEffect(() => {
    if (!currentBot) return;

    channel.current = socket.channel(`tracker:${currentBot?.app_id}`, { from: 'RestRequestTrackerContextProvider' });

    channel.current
      ?.join()
      .receive('ok', () => {
        console.log(`[RestRequestTrackerContextProvider] Joined "tracker:${currentBot?.app_id}" channel for REST request tracker updates`);
      })
      .receive('error', (resp) => {
        console.log(
          `[RestRequestTrackerContextProvider] Cannot join "tracker:${currentBot?.app_id}" channel for REST request tracker updates`,
          resp,
        );
      });

    channel.current?.onClose(() => {
      console.log(`[RestRequestTrackerContextProvider] Left "tracker:${currentBot?.app_id}"`);
    });

    channel.current?.on('rest_request:latest', ({ data }) => {
      const { entries } = data;

      setUsage(entries);
    });

    return () => {
      channel.current?.leave();
    };
  }, [currentBot]);

  return <RestRequestTrackerContext.Provider value={{ restRequestUsage: usage }}>{children}</RestRequestTrackerContext.Provider>;
}
