import { useState, useRef, useEffect, FormEvent } from 'react';
import _ from 'lodash';
import { Channel } from 'phoenix';

import { TradingPair, RemoteOrderBook, SnapshotOrderBookResponse, Opportunity } from '../../../shared/interfaces/bot';
import socket from '../../../socket';
import { OrderBookVisualizer } from '../../order-book/visualizer/visualizer';
import axios from '../../../shared/custom-axios';
import { AppToaster } from '../../../shared/app-toaster';
import { Button, ControlGroup, HTMLSelect, InputGroup, Intent } from '@blueprintjs/core';
import { getDepths } from '../../instrument/utils';

interface Props {
  tradingPair: TradingPair;
}

export function TradingPairEditorOrderBooks({ tradingPair }: Props) {
  const { primary_instrument, primary_order_book, secondary_instrument, secondary_order_book } = tradingPair;
  const [orderBooks, setOrderBooks] = useState<{ [key: number]: RemoteOrderBook }>({});
  const [ratioOrderBookData, setRatioOrderBookData] = useState<SnapshotOrderBookResponse | null>(null);
  const [priceLevelsNumber, setPriceLevelsNumber] = useState('10');
  const [orderBookGrouping, setOrderBookGrouping] = useState('');
  const [orderBookGroupings, setOrderBookGroupings] = useState<number[]>([]);
  const [ratioOpportunity, setRatioOpportunity] = useState<string | number>();
  const [opportunities, setOpportunities] = useState<any[]>([]);
  let orderBookChannel = useRef<Channel | null>(null);

  useEffect(() => {
    orderBookChannel.current = socket.channel('order_book:lobby', {
      trading_pair_id: tradingPair.id,
      limit: 5,
      from: 'TradingPairEditorOrderBooks',
    });

    orderBookChannel.current
      ?.join()
      .receive('ok', () => {
        console.log(
          `[TradingPairEditorOrderBooks][#${tradingPair.id} ${tradingPair.name}] Joined "order_book" channel for receiving order book updates`,
        );
      })
      .receive('error', (resp) => {
        console.log(
          `[TradingPairEditorOrderBooks][#${tradingPair.id} ${tradingPair.name}] Cannot join "order_book" channel for receiving order book updates`,
          resp,
        );
      });

    orderBookChannel.current?.onClose(() => {
      console.log(`[TradingPairEditorOrderBooks][#${tradingPair.id} ${tradingPair.name}] Left "order_book" channel`);
    });

    orderBookChannel.current?.on('list_orders', ({ order_book_id, data }) => {
      setOrderBooks((prevState) => {
        return { ...prevState, [order_book_id]: data };
      });
    });

    orderBookChannel.current?.push('show_order_books', {}).receive('ok', () => {
      // setShowOrderBooks(true);
    });

    setOrderBookGroupings(getDepths(primary_instrument));
    let opportunities = tradingPair.opportunities.map((opp: Opportunity) => ({
      value: opp.id,
      text: `Opp #${opp.id} - ${Math.abs(opp.percentage)}% - ${opp.side}`,
    }));
    opportunities.unshift({ value: 0, text: 'Select Opportunity' });
    setOpportunities(opportunities);

    return () => {
      orderBookChannel.current?.leave();
      // setShowOrderBooks(false);
      setOrderBooks({});
    };
  }, [primary_instrument, tradingPair.id, tradingPair.name]);

  const handleOrderBookGroupingChange = (event: FormEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { value } = event.currentTarget;
    setOrderBookGrouping(value);
  };

  const handleSelectOpportunity = (event: FormEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { value } = event.currentTarget;
    setRatioOpportunity(value);
  };

  const handleGetSnapshot = () => {
    fetchOrderBookSnapshot(primary_order_book.id);
    if (!ratioOpportunity) {
      fetchOrderBookSnapshot(secondary_order_book.id);
    }
  };

  const fetchOrderBookSnapshot = (orderBookId: number) => {
    let apiUrl = `/api/order_books/${orderBookId}/snapshot?limit=${priceLevelsNumber}&grouping=${orderBookGrouping}`;
    if (ratioOpportunity && orderBookId === primary_order_book.id) {
      apiUrl = `${apiUrl}&trading_pair_id=${tradingPair.id}&opportunity_id=${ratioOpportunity}`;
    }

    axios
      .get<SnapshotOrderBookResponse>(apiUrl)
      .then((response) => {
        console.log(response.data);
        setOrderBooks((prevState) => {
          return { ...prevState, [orderBookId]: { sell: response.data.order_book.sell.reverse(), buy: response.data.order_book.buy } };
        });
        if (ratioOpportunity && ratioOpportunity > 0) {
          setRatioOrderBookData(response.data);
        }
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 2000 });
      });
  };

  return (
    <>
      <div>
        <ControlGroup className="mt-2 text-right mb-2">
          <HTMLSelect id="main_exchange" name="main_exchange" value={orderBookGrouping} onChange={handleOrderBookGroupingChange}>
            {orderBookGroupings.map((grouping, index) => (
              <option value={grouping} key={index}>
                {grouping}
              </option>
            ))}
          </HTMLSelect>
          <InputGroup
            placeholder="Number of price levels..."
            value={priceLevelsNumber}
            onChange={(e: FormEvent<HTMLInputElement>) => setPriceLevelsNumber(e.currentTarget.value)}
          />
          <HTMLSelect
            id="ratio_opportunity"
            name="ratio_opportunity"
            placeholder="Select opportunity"
            value={ratioOpportunity}
            onChange={handleSelectOpportunity}
          >
            {opportunities.map(({ value, text }) => (
              <option value={value} key={value}>
                {text}
              </option>
            ))}
          </HTMLSelect>
          <Button icon="refresh" intent={Intent.PRIMARY} onClick={() => handleGetSnapshot()}>
            Refresh
          </Button>
        </ControlGroup>
        {ratioOrderBookData ? (
          <div className="mt-3 mb-3">
            <h5 className="font-bold pb-1">Ratio & Implied Price:</h5>
            <span className="mr-3 font-semibold">Implied Volume: {ratioOrderBookData?.implied_volume}</span>
            <span className="mr-3 font-semibold">Implied Price: {ratioOrderBookData?.implied_price}</span>
            <span className="mr-3 font-semibold">
              Activate Ratio Distance: {ratioOrderBookData.activate_ratio_distance ? ratioOrderBookData.activate_ratio_distance * 10000 : 0}
              bips
            </span>
            <span className="font-semibold">Tick Size: {ratioOrderBookData?.ratio_tick_size}</span>
          </div>
        ) : null}
        {ratioOrderBookData ? (
          <div style={{ maxHeight: '70vh', overflow: 'auto' }}>
            <div>
              <h3 className="mb-3">
                {_.upperFirst(primary_instrument.main_exchange)} {_.upperFirst(primary_instrument.sub_exchange)} (
                {primary_instrument.symbol})
              </h3>
              <OrderBookVisualizer
                sell={orderBooks[primary_order_book.id]?.sell}
                buy={orderBooks[primary_order_book.id]?.buy}
                impliedPrice={ratioOrderBookData?.implied_price}
                currentOrder={ratioOrderBookData?.order?.amount}
                activatedDistance={ratioOrderBookData?.activate_ratio_distance}
                instrument={primary_instrument}
              />
            </div>
          </div>
        ) : (
          <div className="grid grid-cols-2 gap-2" style={{ maxHeight: '70vh', overflow: 'auto' }}>
            <div>
              <h3 className="mb-3">
                {_.upperFirst(primary_instrument.main_exchange)} {_.upperFirst(primary_instrument.sub_exchange)} (
                {primary_instrument.symbol})
              </h3>
              <OrderBookVisualizer
                sell={orderBooks[primary_order_book.id]?.sell}
                buy={orderBooks[primary_order_book.id]?.buy}
                instrument={primary_instrument}
              />
            </div>

            <div>
              <h3 className="mb-3">
                {_.upperFirst(secondary_instrument.main_exchange)} {_.upperFirst(secondary_instrument.sub_exchange)} (
                {secondary_instrument.symbol})
              </h3>

              <OrderBookVisualizer
                sell={orderBooks[secondary_order_book.id]?.sell}
                buy={orderBooks[secondary_order_book.id]?.buy}
                instrument={secondary_instrument}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}
