export interface ApiEndpoint {
  id: number;
  base_url: string;
  is_activated: boolean;
  inserted_at: string;
}

export enum BotState {
  Connected = 'connected',
  Disconnected = 'disconnected',
}

export interface Bot {
  id: number;
  app_id: string;
  name: string;
  activated_at: string;
  current_api_endpoint: ApiEndpoint;
  api_endpoints: Array<ApiEndpoint>;
  inserted_at: string;
  updated_at: string;
}

export interface BotHealth {
  connectivity_status: BotState;
  last_pong_response_at: string;
}

export interface Exchange {
  name: string;
  type: ExchangeType;
  main_exchange: string;
  sub_exchange: string;
}

export enum ExchangeType {
  CEX = 'cex',
  DEX = 'dex',
}

export enum ContractType {
  Linear = 'linear',
  Inverse = 'inverse',
  Quanto = 'quanto',
}

export enum ThemeMode {
  Light = 'light',
  Dark = 'dark',
}

export enum TimezoneOption {
  Bali = 'bali',
  Utc = 'utc',
  Browser = 'browser',
}

export interface UserPreferences {
  id: number;
  theme: ThemeMode;
}

export interface UserResponse {
  data: User;
}

export interface User {
  id: number;
  name: string;
  display_name: string;
  title: string;
  email: string;
  preferences: UserPreferences;
}

export interface OrderBookResponse {
  data: OrderBook;
}

export enum ConnectivityStatus {
  Started = 'started',
  Stopped = 'stopped',
}

export interface OrderBook {
  main_exchange: string;
  sub_exchange: string;
  id: number;
  name: string;
  description: string;
  instrument_id: string;
  instrument: Instrument;
  artificial_depth: string;
  artificial_depth_distance: boolean;
  status: ConnectivityStatus;
  supported_currencies: Array<string>;
  is_default: boolean;
}

export interface OrderBooksListResponse {
  data: OrderBook[];
}

export interface PrivateStreamResponse {
  data: PrivateStream;
}

export interface PrivateStream {
  id: number;
  name: string;
  description: string;
  main_exchange: string;
  sub_exchange: string;
  status: ConnectivityStatus;
  instrument_id: string;
  instrument: Instrument;
  account_id: string;
  account: Account;
  is_default: boolean;
  bot_id: number;
}

export interface PrivateStreamsListResponse {
  data: PrivateStream[];
}

export interface ContractStreamResponse {
  data: ContractStream;
}

export interface ContractStream {
  id: number;
  name: string;
  description: string;
  status: ConnectivityStatus;
  is_default: boolean;
  bot_id: number;
  instrument: Instrument;
}

export interface ContractStreamsListResponse {
  data: ContractStream[];
}

export enum CurrencyType {
  Standard = 'standard',
  StableCoin = 'stablecoin',
  WrappedToken = 'wrapped_token',
  MultiplierToken = 'multiplier_token',
  NFT = 'nft',
}

export interface Money {
  amount: number;
  currency: string;
}

export interface GenericOrder {
  price: Money;
  volume: Money;
  total_value: Money;
}

export interface ActiveOrder extends GenericOrder {
  account_id: number;
  internal_id: string;
  external_id: string;
  original: GenericOrder;
  opportunity: Opportunity;
  local_order_book: {
    best_price: number;
  };
  trading_pair: {
    id: number;
    name: string;
    strategy_type: StrategyType;
    wick_catcher_id: number;
    primary_instrument: {
      main_exchange: string;
      symbol: string;
    };
  };
  trading_side: string;
  submission_time: string;
  last_submission_time: string;
}

export interface Network {
  id: number;
  blockchain: string;
  name: string;
  currency_symbol: string;
  block_explorer_url: string;
  http_url: string;
  ws_url: string;
  chain_id: number;
}

export interface NetworkResponse {
  data: Network;
}

export interface NetworksListResponse {
  data: Network[];
}

export enum DexType {
  DeFi = 'defi',
  NFT = 'nft',
}

export interface DexNetwork {
  id?: number;
  type: DexType;
  blockchain: string;
  main_exchange: string;
  router_address: string;
  subgraph_url: string;
  network_id: string;
  network?: Network;
}

export interface DexNetworkResponse {
  data: DexNetwork;
}

export interface DexNetworksListResponse {
  data: DexNetwork[];
}

export interface NFT {
  token_id: string;
  contract: string;
  name: string;
}

export interface NFTsResponse {
  data: NFT[];
}

export interface Instrument {
  id: number;
  exchange_type: ExchangeType;
  name: string;
  symbol: string;
  main_exchange: string;
  sub_exchange: string;
  margin_mode: string;
  base_currency: string;
  base_currency_type: string;
  base_currency_multiplier: string;
  backing_base_currency: string;
  quote_currency: string;
  quote_currency_type: string;
  quote_currency_multiplier: string;
  backing_quote_currency: string;
  volume_currency: string;
  contract_type: ContractType;
  contract_size: number;
  tick_size: number;
  lot_size: number;
  expiry_date: string;
  expiry_type: string;
  funding_interval: number;
  status: string;
  list_time: string;
  pair_address: string;
  token0_address: string;
  token0_decimals: number;
  token1_address: string;
  token1_decimals: number;
  dex_network_id: number;
  dex_network: DexNetwork;
  updated_at: string;
}

export interface InstrumentResponse {
  data: Instrument;
}

export interface InstrumentsListResponse {
  entries: Instrument[];
  page_number: number;
  page_size: number;
  total_entries: number;
  total_pages: number;
}

export interface ScheduledJob {
  name: string;
  overlap: string;
  schedule: string;
  state: string;
  task: string;
  timezone: string;
}

export enum AccountType {
  Main = 'main',
  Sub = 'sub',
}

export interface Account {
  id: number;
  type: AccountType;
  name: string;
  secret_identifier: string;
  exchange_type: ExchangeType;
  main_exchange: string;
  blockchain: string;
  address: string;
  blur_max_fee_per_tx: number;
  blur_max_fee_per_hour: number;
}

export interface AccountResponse {
  data: Account;
}

export interface AccountsListResponse {
  data: Account[];
}

export interface ApiKeys {
  main_exchange: string;
  exchange_type: ExchangeType;
  identifier: string;
  api_key: string;
  secret_key: string;
  passphrase: string;
  private_key: string;
  is_present: boolean;
}

export enum StrategyType {
  Normal = 'normal',
  PairsTrading = 'pairs_trading',
  WickCatcher = 'wick_catcher',
  NFTMarketMaking = 'nft_market_making',
}

export interface TradingPairsResponse {
  entries: Instrument[];
  page_number: number;
  page_size: number;
  total_entries: number;
  total_pages: number;
}

export interface TradingPairResponse {
  data: TradingPair;
}

export interface TradingPair {
  id: number;
  name: string;
  description: string;
  strategy_type: StrategyType;
  short_mode: string;
  long_mode: string;
  paused_at: string;
  paused_reason: { code: string; message: string; action_needed: string } | undefined;
  primary_account: Account;
  primary_instrument: Instrument;
  primary_order_book: OrderBook;
  primary_private_stream: PrivateStream;
  primary_contract_stream: ContractStream;
  secondary_account: Account;
  secondary_instrument: Instrument;
  secondary_order_book: OrderBook;
  secondary_private_stream: PrivateStream;
  secondary_contract_stream: ContractStream;
  tertiary_account: Account;
  tertiary_instrument: Instrument;
  tertiary_order_book: OrderBook;
  tertiary_private_stream: PrivateStream;
  tertiary_contract_stream: ContractStream;
  currency: string;
  defi_quota: string;
  defi_replenish_delay: string;
  default_debounce: number | undefined;
  opportunities: Opportunity[];
  wick_catcher_id: number;
  hedge_bot_id: number;
  bot_id: number;
  inserted_at: string;
  updated_at: string;
  last_active_at: string;
}

export enum PairsSortingOptions {
  MostRecentActive = 'most_recent_active',
  PrimaryExchange = 'primary_exchange',
  PrimaryInstrument = 'primary_instrument',
  SecondaryExchange = 'secondary_exchange',
  SecondaryInstrument = 'secondary_instrument',
  Alphabet = 'alphabet',
}

export interface TradeSide {
  id: number | undefined;
  ui_position: number | undefined;
  instrument: Instrument;
  account: Account;
  private_stream: PrivateStream | undefined;
  contract_stream: ContractStream | undefined;
  order_book: OrderBook | undefined;
  wick_catcher: WickCatcher;
  bot_id: number;
  inserted_at: string | undefined;
  updated_at: string | undefined;
}

export interface WickCatcher {
  id: number;
  name: string;
  description: string;
  short_mode: string;
  long_mode: string;
  currency: string;
  debounce: number;
  quantity: number;
  min_target: number;
  emergency_cancel: number;
  max_individual: number;
  distance: number;
  tp_delay: number;
  quota: number;
  market_tracking_settings: WickCatcherMarketTrackingSettings;
  exit_settings: WickCatcherExitSettings;
  overall_volume_tiers: WickCatcherTierSettings[];
  specific_volume_tiers: WickCatcherTierSettings[];
  overall_movement_tiers: WickCatcherTierSettings[];
  specific_movement_tiers: WickCatcherTierSettings[];
  pairs: TradingPair[];
  opportunity_settings: WickCatcherOpportunitySettings[];
  high_low_pause_settings: WickCatcherHighLowPauseSettings;
  bot_id: number;
  last_active_at: string;
  inserted_at: string;
  updated_at: string;
}

export interface WickCatcherMarketTrackingSettings {
  debounce: number;
  overall_time_frame: number;
  current_time_frame: number;
  movement_time_frame: number;
}

export interface WickCatcherExitSettings {
  percentage: number;
  max_individual: number;
  debounce_amendment: number;
  movement_index: number;
  tier: number;
  movement_time_frame: number;

  activate_ratio_distance: number;
  implied_ratio_multiplier: number;
  ec_ratio_change: number;
  ratio_tick_size: number;

  implied_volume_multiplier: number;
  ec_replacement_debounce: number;

  disable_immediately_exit: boolean;
  secondary_hedge_tier: number;
}

export interface WickCatcherHighLowPauseSettings {
  overall_enabled: boolean;
  overall_timeframe: string | undefined;
  overall_sensitivity: number | undefined;
  overall_pause_check_debounce: number | undefined;
  overall_resume_check_debounce: number | undefined;
  primary_enabled: boolean;
  primary_timeframe: string | undefined;
  primary_sensitivity: number | undefined;
  primary_pause_check_debounce: number | undefined;
  primary_resume_check_debounce: number | undefined;
}

export interface WickCatcherTierSettings {
  tier: number;
  trigger_threshold: number | string;
  short_multiplier: number | string;
  long_multiplier: number | string;
}

export interface WickCatcherEffectiveTier {
  trigger_market: string;
  type: 'volume_index' | 'movement_index';
  tier_settings: WickCatcherTierSettings;
}

export interface WickCatcherOpportunitySettings {
  id: number;
  side: string;
  quantity: number;
  min_target: number;
  max_individual: number;
  emergency_cancel: number;
  distance: number;
  debounce: number;
  tp_delay: number;
  quota: number;
  currency: string;
  key: string;
}

export interface WickCatcherSettingsTemplate {
  id: number;
  name: string;
  market_tracking_settings: WickCatcherMarketTrackingSettings;
  exit_settings: WickCatcherExitSettings;
  overall_volume_tiers: WickCatcherTierSettings[];
  specific_volume_tiers: WickCatcherTierSettings[];
  overall_movement_tiers: WickCatcherTierSettings[];
  specific_movement_tiers: WickCatcherTierSettings[];
  user: User;
  inserted_at: string;
  updated_at: string;
}

export interface WickCatcherSettingsTemplateListResponse {
  data: WickCatcherSettingsTemplate[];
}

export interface OpportunitiesListResponse {
  data: Opportunity[];
}
export interface Opportunity {
  id: number;
  percentage: number;
  ratio: number;
  quantity: number;
  offset: number;
  max_individual: number;
  quota: number;
  replenish_delay: number;
  emergency_cancel: number;
  offset_ec: number;
  side: string;
  direction: string;
  debounce: number;
  mode: string;
  defi_slippage: number;
  is_flip: boolean;
  exit: string;
  effective_tier: WickCatcherEffectiveTier | undefined;
  trading_pair_id: number;
  bot_id: number;
}

export interface OpportunityLastSkip {
  reason: string;
  timestamp: string;
}

export interface OpportunitySession {
  opportunity_id: number;
  replenish: number;
  quota: number;
  filled_volume: number;
  volume: number;
  internal_id: string;
  pulser_countdown: number;
  is_active: boolean;
  stop_loss_condition_counter: number;
  last_skip: OpportunityLastSkip | undefined;
}

export interface RestRequestUsage {
  day: number;
  hour: number;
  minute: number;
}

export interface GeneratedOrder extends GenericOrder {
  wall_price: number;
}

export interface OpportunityLogs {
  app_id: string;
  trading_pair_id: number;
  opportunity_id: number;
  opportunity_session: object;
  opportunity_session_state: string;
  pulser_action: string;
  generated_order: {
    wall_price: number;
    price: number;
    volume: number;
    total_value: number;
  };
  primary_exit: {
    side: string;
  };
  debounce: number;
  timestampt: string;
}

export interface SnapshotOrderBookResponse {
  implied_price: number;
  order?: { amount: number; currency: string };
  order_book: RemoteOrderBook;
  implied_volume?: number;
  activate_ratio_distance?: number;
  ratio_tick_size?: number;
}

export interface RemoteOrderBook {
  sell: PriceLevel[];
  buy: PriceLevel[];
}

export interface PriceLevel {
  id: number;
  price: number;
  volume: number;
  total_value: {
    [key: string]: number;
  };
  ratio?: number;
  top_amount?: number;
  bottom_amount?: number;
  distance?: number;
}

export interface BotEvent {
  publisher_id: string;
  action: string;
  data: WebSocketMessage;
  timestamp: number;
}

enum WebSocketMessageDirection {
  In = 'in',
  Out = 'out',
}

export interface WebSocketMessagesListResponse {
  data: WebSocketMessage[];
}

export interface WebSocketMessage {
  id: number;
  internal_message_id: string;
  private_stream_id: number;
  type: string;
  payload: string;
  direction: WebSocketMessageDirection;
  bot_id: number;
}

export interface EmergencyCancelOrdersListResponse {
  entries: EmergencyCancelOrder[];
  page_number: number;
  page_size: number;
  total_entries: number;
  total_pages: number;
}

export interface EmergencyCancelOrder {
  id: number;
  session_id: string;
  session_start_time: string;
  session_end_time: string;
  start_time: string;
  end_time: string;
  exchange_transaction_time: string;
  exchange_event_time: string;
  latency_pre_http: number;
  latency_http_request: number;
  latency_post_processed: number;
  latency_http_response: number;
  latency_http: number;
  latency_response: number;
  latency_ec: number;
  bbo_price_diff: number;
  ec: { best_price: number; percentage: number; cancel_price: number; should_cancel: boolean };
  offset_ec: { best_price: number; percentage: number; cancel_price: number; should_cancel: boolean };
  bbo_ec: { best_price: number; percentage: number; cancel_price: number; should_cancel: boolean };
  side: string;
  external_id: string;
  internal_id: string;
  original_price: number;
  original_volume: number;
  should_cancel: boolean;
  opportunity_id: number; // # This field is for backward-compabitility, will be replaced by the 'opportunity' field below
  opportunity_percentage: number; // # This field is for backward-compabitility, will be replaced by the 'opportunity' field below
  opportunity: {
    id: number;
    direction: string;
    percentage: number;
  };
  inserted_at: string;
}

export interface TradeFeedOrderFillsListResponse {
  entries: TradeFeedOrderFill[];
  page_number: number;
  page_size: number;
  total_entries: number;
  total_pages: number;
}

export enum HedgeColor {
  Blue = 'blue',
  Orange = 'orange',
  White = 'white',
  Green = 'green',
  // gray for unknown
  Gray = 'gray',
  // danger for non-hedge
  Danger = 'danger',
}

export enum TradeFeedDisplayView {
  CompactView = 'compact_view',
  FullView = 'full_view',
}

export interface TradeFeedOrderFill {
  id: number;
  internal_trade_id: string | undefined;
  trade_id: string | undefined;
  external_id: string;
  internal_id: string;
  side: string;
  last_fill_volume: Money | null;
  last_fill_price: Money | null;
  avg_fill_price: Money | null;
  last_fill_value: Money | null;
  original_volume: Money | null;
  original_price: Money | null;
  placement_logic: { [key: string]: string } | null;
  lag_time: number;
  total_lag_time: number;
  achieved: number;
  rest_start_bot_ts: string;
  rest_end_bot_ts: string;
  new_tx_ts: string;
  new_event_ts: string;
  new_bot_ts: string;
  fill_tx_ts: string;
  fill_event_ts: string;
  fill_bot_ts: string;
  replenish_id: number;
  internal_message_id: string;
  private_stream_id: number;
  opportunity_id: number;
  secondary_hedge_decision: {
    should_hedge: boolean;
    message: string;
  };
  opportunity: {
    direction: string;
    percentage: number;
    ratio: number;
    quantity: number;
    emergency_cancel: number;
    is_flip: boolean;
    is_stop_loss: boolean;
    effective_tier: WickCatcherEffectiveTier | undefined;
    entry_order_fill: EntryOrderFill[] | [];
  };
  wick_catcher_exits_count: number | undefined;
  wick_catcher_entries_count: number | undefined;
  trading_pair_id: number;
  trading_pair: OrderFillTradingPair;
  bot_id: number;
  inserted_at: string;
  updated_at: string;
  hedge_orders: TradeFeedHedgeOrder[];
  color?: HedgeColor;
}

export interface EntryOrderFill {
  trade_id: string;
  internal_trade_id: string;
  last_fill_price: Money | null;
  last_fill_volume: Money | null;
}

export interface LagTimeEvent {
  id: string;
  name: string | JSX.Element;
  timestamp: string;
  source: 'bot' | 'exchange';
  type: 'order_fill' | 'hedge_order' | 'hedge_update';
  diff_time: number;
}

export enum HedgeState {
  Init = 'init',
  Hedging = 'hedging',
  Retrying = 'retrying',
  Finished = 'finished',
  Failed = 'failed',
}

export enum HedgeTriggerType {
  Auto = 'auto',
  Manual = 'manual',
}

export enum NonRetryableReason {
  NonSupportedError = 'non_supported_error',
  MaxRetriesExceeded = 'max_retries_exceeded',
}

export interface TradeFeedHedgeOrder {
  id: number;
  primary_internal_trade_id: string;
  internal_id: string;
  external_id: string;
  price: Money | null;
  avg_fill_price: Money | null;
  volume: Money | null;
  total_value: Money | null;
  side: string;
  init_bot_ts: string;
  rest_start_bot_ts: string;
  rest_end_bot_ts: string;
  child_orders: TradeFeedHedgeOrder[];
  hedge_updates: TradeFeedHedgeUpdate[];
  is_batch: boolean | null;
  attempts_counter: number;
  hedge_state: HedgeState;
  trigger_type: HedgeTriggerType;
  manual_retried_at: string;
  failed_reason: string;
  non_retryable_reason: NonRetryableReason;
  order_fills: { id: number }[];
}

export interface TradeFeedHedgeUpdatesListResponse {
  data: TradeFeedHedgeUpdate[];
}

export interface TradeFeedHedgeUpdate {
  id: number;
  internal_trade_id: string | undefined;
  trade_id: string | undefined;
  external_id: string;
  internal_id: string;
  side: string;
  status: string;
  last_fill_volume: Money | null;
  last_fill_price: Money | null;
  avg_fill_price: Money | null;
  last_fill_value: Money | null;
  lag_time: Money | null;
  transaction_time: string;
  event_time: string;
  creation_time: string;
  replenish_id: number;
  internal_message_id: string;
  private_stream_id: number;
  opportunity_id: number;
  trading_pair_id: number;
  bot_id: number;
  inserted_at: string;
  updated_at: string;
}

export interface MovingPairResponse {
  data: any;
}
export interface OrderFillTradingPair {
  id: number;
  name: string;
  strategy_type: StrategyType;
  currency: string;
  primary_instrument: { exchange_type: string };
  bot: {
    name: string;
    app_id: string;
  };
}

export interface BotSetting {
  id: number;
  key: string;
  value: string;
  description: string;
  bot_id: number;
  updated_at: Date;
  created_at: Date;
}

export interface BinanceRateLimit {
  account_id: number;
  main_exchange: string;
  spot_margin: RateLimitItem;
  futures_linear: RateLimitItem;
  futures_inverse: RateLimitItem;
}

export interface RateLimitItem {
  weight_limit: RateLimitUsageInfo;
  order_limits: RateLimitUsageInfo[];
}

export interface RateLimitUsageInfo {
  used: number;
  limit: number;
  buffer: number;
  interval: number;
}

export interface OpportunityRateLimitError {
  opportunityId: number;
  tradingPairId: number;
  error: string;
}
