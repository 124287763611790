import { createContext, useContext, useEffect, useRef, useState } from 'react';

import { OpportunitySession } from '../shared/interfaces/bot';
import { Channel } from 'phoenix';
import socket from '../socket';
import { BotsManagerContext } from './bots-manager';

export interface State {
  opportunitySessions: { [key: number]: OpportunitySession };
}

const defaultState: State = {
  opportunitySessions: {},
};

export const OpportunitySessionContext = createContext<State>(defaultState);

export function OpportunitySessionContextProvider({ children }: any) {
  const { currentBot } = useContext(BotsManagerContext);

  let channel = useRef<Channel | null>(null);

  const [opportunitySessions, setOpportunitySessions] = useState<{
    [key: number]: OpportunitySession;
  }>({});

  useEffect(() => {
    if (!currentBot) return;

    channel.current = socket.channel(`opportunity_session:${currentBot?.app_id}`, { from: 'OpportunitySessionContextProvider' });

    channel.current
      ?.join()
      .receive('ok', () => {
        console.log(
          `[OpportunitySessionContextProvider] Joined "opportunity_session:${currentBot?.app_id}" channel for opportunity sessions real-time updates`,
        );
      })
      .receive('error', (resp) => {
        console.log(
          `[OpportunitySessionContextProvider] Cannot join "opportunity_session:${currentBot?.app_id}" channel for opportunity sessions real-time updates`,
        );
      });

    channel.current?.onClose(() => {
      console.log(`[OpportunitySessionContextProvider] Left "opportunity_session:${currentBot?.app_id}" `);
    });

    channel.current?.on('opportunity_sessions:update', ({ data }) => {
      const { opportunity_sessions } = data;
      setOpportunitySessions(opportunity_sessions);
    });

    return () => {
      channel.current?.leave();
    };
  }, [currentBot]);

  return <OpportunitySessionContext.Provider value={{ opportunitySessions }}>{children}</OpportunitySessionContext.Provider>;
}
