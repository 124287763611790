import React, { useEffect, useState } from 'react';
import { ContractType, Instrument, PriceLevel } from '../../../shared/interfaces/bot';

import sharedStyles from '../../../app/app.module.css';
import styles from './visualizer.module.css';
import { Popover2 } from '@blueprintjs/popover2';
import _ from 'lodash';

interface Props {
  sell: PriceLevel[];
  buy: PriceLevel[];
  instrument: Instrument;
  impliedPrice?: number | null;
  currentOrder?: number | null;
  activatedDistance?: number | null;
}

export function OrderBookVisualizer({ sell, buy, instrument, impliedPrice, currentOrder, activatedDistance }: Props) {
  const [valueDecimals, setValueDecimals] = useState(0);
  const [selectedTicker, setSelectedTicker] = useState<PriceLevel>();

  useEffect(() => {
    // Re-calculate the decimals number of tick_size which is used to round up
    // the values in Equivalent Value column
    const tickSize = instrument.tick_size;
    const decimals = tickSize?.toString().split('.')[1];

    if (decimals) {
      setValueDecimals(decimals.length);
    } else {
      setValueDecimals(0);
    }
  }, [instrument]);

  useEffect(() => {
    setSelectedTicker(undefined);
  }, [sell, buy]);

  const handleSelectTicker = (ticker: PriceLevel) => {
    setSelectedTicker(ticker);
  };

  const distance = (ticker1: PriceLevel, ticker2: PriceLevel) => {
    return Math.abs(ticker2.price - ticker1.price) / ticker1.price;
  };

  const weight = (ticker: PriceLevel, distance: number | undefined, range: number) => {
    if (distance === 0) {
      return ticker.price * ticker.volume;
    } else if (distance && distance / range < 1) {
      return (1 - distance / range) * ticker.price * ticker.volume;
    } else {
      return 0;
    }
  };

  const getRatioWeight = (ticker: PriceLevel, range: number) => {
    if (!selectedTicker) {
      return '-';
    }

    const tickleDistance = distance(ticker, selectedTicker);
    const w = weight(ticker, tickleDistance, range);
    return `${w.toFixed(0)}`;
  };

  const getRatioDistance = (ticker: PriceLevel) => {
    if (!selectedTicker) {
      return '-';
    }

    const tickleDistance = distance(ticker, selectedTicker);

    return `${(tickleDistance * 10000).toFixed(0)}`;
  };

  const ratioCalculationDetails = (ticker: PriceLevel, range: number, side: string, sell: PriceLevel[], buy: PriceLevel[]) => {
    const fullOrderBook = sell.concat([...buy]).sort((a, b) => b.price - a.price);
    let tickerIndex = fullOrderBook.findIndex((tick: any) => tick.price === ticker.price);
    let aboveSide: PriceLevel[] = [];
    let belowSide: PriceLevel[] = [];

    if (tickerIndex < 0) {
      return <span>Not found ticker in orderbook</span>;
    }

    if (side === 'short') {
      aboveSide = fullOrderBook
        .filter((t: PriceLevel) => t.price > ticker.price)
        .map((x: PriceLevel) => Object.assign({}, x, { distance: distance(ticker, x) }));

      belowSide = fullOrderBook
        .filter((t: PriceLevel) => t.price <= ticker.price)
        .map((x: PriceLevel) => Object.assign({}, x, { distance: distance(ticker, x) }));
    } else {
      aboveSide = fullOrderBook
        .filter((t: PriceLevel) => t.price < ticker.price)
        .map((x: PriceLevel) => Object.assign({}, x, { distance: distance(ticker, x) }));

      belowSide = fullOrderBook
        .filter((t: PriceLevel) => t.price >= ticker.price)
        .map((x: PriceLevel) => Object.assign({}, x, { distance: distance(ticker, x) }));
    }

    const topAmount = aboveSide.reduce((acc, t, currentIndex) => {
      return acc + weight(t, t.distance, range);
    }, 0);
    const bottomAmount = belowSide.reduce((acc, t, currentIndex) => {
      return acc + weight(t, t.distance, range);
    }, 0);

    return (
      <div className="p-2 flex flex-col">
        <ol className="bp4-list bp4-list-unstyled text-center" style={{ maxHeight: '300px', overflow: 'auto' }}>
          <li className="bp4-monospace-text bp4-text-small">
            <div className="flex flex-row items-center">
              <div className="w-20 font-semibold">Distance</div>|<div className="w-20 font-semibold">Weight</div>
            </div>
          </li>
          {aboveSide
            .sort((t1, t2) => (t2.distance || 0) - (t1.distance || 0))
            .map((item: PriceLevel, i: number) => {
              const tickleDistance = distance(ticker, item);
              return (
                <li className="bp4-monospace-text bp4-text-small">
                  <div className="flex flex-row items-center">
                    <div className="w-20">{(tickleDistance * 100).toFixed(2)}%</div>|
                    <div className="w-20">{weight(item, tickleDistance, range).toFixed(2)}</div>
                  </div>
                </li>
              );
            })}
        </ol>

        <ol className="bp4-list bp4-list-unstyled text-center mt-2 mb-2">
          <li className="bp4-monospace-text font-semibold">Total Weight Above: {topAmount.toFixed(1)}</li>
          <li className="bp4-monospace-text font-semibold">Ratio: {(topAmount / bottomAmount).toFixed(2)}</li>
          <li className="bp4-monospace-text font-semibold">Total Weight Below: {bottomAmount.toFixed(1)}</li>
        </ol>

        <ol className="bp4-list bp4-list-unstyled text-center flex flex-col-reverse" style={{ maxHeight: '300px', overflow: 'auto' }}>
          {belowSide
            .sort((t1, t2) => (t2.distance || 0) - (t1.distance || 0))
            .map((item: PriceLevel, i: number) => {
              const tickleDistance = distance(ticker, item);
              return (
                <li className="bp4-monospace-text bp4-text-small">
                  <div className="flex flex-row items-center">
                    <div className="w-20">{(tickleDistance * 100).toFixed(2)}%</div>|
                    <div className="w-20">{weight(item, tickleDistance, range).toFixed(2)}</div>
                  </div>
                </li>
              );
            })}
        </ol>
      </div>
    );
  };

  return (
    <>
      <div className="flex flex-row">
        <div>
          {(sell?.length > 0 || buy?.length > 0) && (
            <table className="w-full border text-xs">
              <thead className="font-medium">
                <tr>
                  <th className="px-2.5 py-1.5 text-left">Price({instrument.quote_currency})</th>

                  {instrument.contract_type === ContractType.Linear && (
                    <th className="px-2.5 py-1.5 text-right">Volume({instrument.base_currency})</th>
                  )}

                  {instrument.contract_type === ContractType.Inverse && <th className="px-2.5 py-1.5 text-right">Volume(Cont)</th>}

                  <th className="px-2.5 py-1.5 text-right">Equivalent Value({instrument.quote_currency})</th>
                  {activatedDistance ? <th className="px-1 py-1 text-right">Weight</th> : null}
                  {activatedDistance ? <th className="px-1 py-1 text-right">Distance</th> : null}
                </tr>
              </thead>
              <tbody className="font-normal">
                {_.cloneDeep(sell)
                  .reverse()
                  .map((priceLevel) => {
                    let volume, totalValue;

                    // If the price of the instrument is small (< $1), show volume in whole numbers
                    if (instrument.quote_currency === 'USDT' && instrument.tick_size <= 1) {
                      volume = priceLevel.volume.toLocaleString('en-US', { maximumFractionDigits: 0 });
                    } else {
                      volume = priceLevel.volume.toLocaleString('en-US', { maximumFractionDigits: 10 });
                    }

                    if (instrument.quote_currency === 'USDT') {
                      totalValue = priceLevel.total_value[instrument.quote_currency.toLowerCase()].toLocaleString('en-US', {
                        maximumFractionDigits: 0,
                      });
                    } else {
                      totalValue = priceLevel.total_value[instrument.quote_currency.toLowerCase()].toLocaleString('en-US', {
                        maximumFractionDigits: valueDecimals,
                      });
                    }

                    return (
                      <tr
                        className={`${sharedStyles.item} whitespace-nowrap cursor-pointer ${
                          selectedTicker && selectedTicker.id === priceLevel.id ? 'bg-blue-400' : null
                        }`}
                        key={priceLevel.price}
                        onClick={() => handleSelectTicker(priceLevel)}
                      >
                        <td
                          className={`px-2.5 py-1.5 text-left font-semibold ${
                            priceLevel.price === impliedPrice ? 'text-purple-600' : 'text-red-500'
                          }`}
                        >
                          {currentOrder && currentOrder === priceLevel.price ? '* ' : null}
                          {priceLevel.price}
                          {priceLevel.ratio && activatedDistance ? (
                            <Popover2
                              content={ratioCalculationDetails(priceLevel, activatedDistance / 2, 'short', sell, buy)}
                              placement={'left'}
                            >
                              <strong className="ml-2 text-red-600" style={{ fontSize: '10px' }}>
                                {priceLevel.ratio?.toFixed(2)}
                              </strong>
                            </Popover2>
                          ) : null}
                        </td>
                        <td className={`px-2.5 py-1.5 text-right ${styles.td}`}>{volume}</td>
                        <td className={`px-2.5 py-1.5 text-right ${styles.td}`}>{totalValue}</td>
                        {priceLevel.ratio && activatedDistance ? (
                          <td className={`px-1 py-1 text-right ${styles.td}`}>{getRatioWeight(priceLevel, activatedDistance / 2)}</td>
                        ) : null}
                        {priceLevel.ratio && activatedDistance ? (
                          <td className={`px-1 py-1 text-right ${styles.td}`}>{getRatioDistance(priceLevel)}</td>
                        ) : null}
                      </tr>
                    );
                  })}
                <tr className={sharedStyles.item}>
                  <td className="px-2.5 py-1.5 text-left"></td>
                  <td className="px-2.5 py-1.5 text-right"></td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
        <div>
          {(sell?.length > 0 || buy?.length > 0) && (
            <table className="w-full border text-xs">
              <thead className="font-medium">
                <tr>
                  <th className="px-2.5 py-1.5 text-left">Price({instrument.quote_currency})</th>

                  {instrument.contract_type === ContractType.Linear && (
                    <th className="px-2.5 py-1.5 text-right">Volume({instrument.base_currency})</th>
                  )}

                  {instrument.contract_type === ContractType.Inverse && <th className="px-2.5 py-1.5 text-right">Volume(Cont)</th>}

                  <th className="px-2.5 py-1.5 text-right">Equivalent Value({instrument.quote_currency})</th>
                  {activatedDistance ? <th className="px-1 py-1 text-right">Weight</th> : null}
                  {activatedDistance ? <th className="px-1 py-1 text-right">Distance</th> : null}
                </tr>
              </thead>
              <tbody className="font-normal">
                {buy.map((priceLevel) => {
                  let volume, totalValue;

                  // If the price of the instrument is small (< $1), show volume in whole numbers
                  if (instrument.quote_currency === 'USDT' && instrument.tick_size <= 1) {
                    volume = priceLevel.volume.toLocaleString('en-US', { maximumFractionDigits: 0 });
                  } else {
                    volume = priceLevel.volume.toLocaleString('en-US', { maximumFractionDigits: 10 });
                  }

                  if (instrument.quote_currency === 'USDT') {
                    totalValue = priceLevel.total_value[instrument.quote_currency.toLowerCase()].toLocaleString('en-US', {
                      maximumFractionDigits: 0,
                    });
                  } else {
                    totalValue = priceLevel.total_value[instrument.quote_currency.toLowerCase()].toLocaleString('en-US', {
                      maximumFractionDigits: valueDecimals,
                    });
                  }

                  return (
                    <tr
                      className={`${sharedStyles.item} whitespace-nowrap cursor-pointer ${
                        selectedTicker && selectedTicker.id === priceLevel.id ? 'bg-blue-400' : null
                      }`}
                      key={priceLevel.price}
                      onClick={() => handleSelectTicker(priceLevel)}
                    >
                      <td
                        className={`px-2.5 py-1.5 text-left font-semibold ${
                          priceLevel.price === impliedPrice ? 'text-purple-600' : 'text-green-500'
                        }`}
                      >
                        {currentOrder && currentOrder === priceLevel.price ? '* ' : null}
                        {priceLevel.price}
                        {priceLevel.ratio && activatedDistance ? (
                          <Popover2
                            content={ratioCalculationDetails(priceLevel, activatedDistance / 2, 'long', sell, buy)}
                            placement={'left'}
                          >
                            <strong className="ml-2 text-green-600" style={{ fontSize: '10px' }}>
                              {priceLevel.ratio?.toFixed(2)}
                            </strong>
                          </Popover2>
                        ) : null}
                      </td>
                      <td className={`px-2.5 py-1.5 text-right ${styles.td}`}>{volume}</td>
                      <td className={`px-2.5 py-1.5 text-right ${styles.td}`}>{totalValue}</td>
                      {priceLevel.ratio && activatedDistance ? (
                        <td className={`px-1 py-1 text-right ${styles.td}`}>{getRatioWeight(priceLevel, activatedDistance / 2)}</td>
                      ) : null}
                      {priceLevel.ratio && activatedDistance ? (
                        <td className={`px-1 py-1 text-right ${styles.td}`}>{getRatioDistance(priceLevel)}</td>
                      ) : null}
                    </tr>
                  );
                })}
                <tr className={sharedStyles.item}>
                  <td className="px-2.5 py-1.5 text-left"></td>
                  <td className="px-2.5 py-1.5 text-right"></td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
}
