import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AnchorButton } from '@blueprintjs/core';

import { APP_LOGIN_NAME } from '../../shared/configs';
import axios from '../../shared/custom-axios';
import { AuthContext } from '../../contexts/auth';

export function LoginPage() {
  const [authUrl, setAuthUrl] = useState('');
  const { setToken } = useContext(AuthContext);
  let navigate = useNavigate();

  useEffect(() => {
    axios.get<any>(`/auth/google/oauth_url`).then((response) => {
      setAuthUrl(response.data.url);
    });

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let newToken = params.get('token');

    if (newToken) {
      setToken && setToken(newToken);

      // Tricky: Make sure the token is already set before navigating to other page
      setTimeout(() => {
        navigate('/bots', { replace: true });
      }, 500);
    }
  }, [setToken, navigate]);

  return (
    <>
      <div className="App-body h-full bg-gray-800">
        <div className="flex justify-center items-center h-screen">
          <div></div>
          <div className="text-center">
            <div className="group -mt-14">
              <p className="font-extrabold text-5xl cursor-default select-none">
                <span className="ml-3.5 text-gray-100">{APP_LOGIN_NAME}</span>
                <span className="ml-2 inline-block align-text-top text-base text-green-600 group-hover:text-green-400">v2</span>
              </p>
            </div>
            <AnchorButton className="mt-5" intent="primary" href={authUrl} large={true}>
              Login with Google
            </AnchorButton>
          </div>
        </div>
      </div>
    </>
  );
}
