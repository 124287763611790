import { useEffect } from 'react';
import { ExchangeType, Instrument, OrderBook } from '../../../../shared/interfaces/bot';

import axios from '../../../../shared/custom-axios';
import { AppToaster } from '../../../../shared/app-toaster';
import { Intent } from '@blueprintjs/core';

interface Props {
  botId: number | undefined;
  instrumentId: string;
  selectedInstrument: Instrument | null;
  loadedOrderBooks: OrderBook[];
  onAfterSelectDefault: (item: OrderBook) => void;
  onAfterCreateDefault: () => void;
}

export function useDefaultOrderBook({
  botId,
  instrumentId,
  selectedInstrument,
  loadedOrderBooks,
  onAfterSelectDefault,
  onAfterCreateDefault,
}: Props) {
  useEffect(() => {
    if (!instrumentId || (selectedInstrument?.exchange_type === ExchangeType.DEX && selectedInstrument?.main_exchange !== 'blur')) {
      return;
    }

    if (loadedOrderBooks.length) {
      const item = loadedOrderBooks.find((orderBook) => orderBook.is_default);

      if (item !== undefined) {
        return onAfterSelectDefault(item);
      }
    }

    // Create a new order book and set it as default when no default one is found

    const formData = {
      name: selectedInstrument?.symbol,
      description: `The default order book of instrument #${selectedInstrument?.id} ${selectedInstrument?.symbol}`,
      instrument_id: instrumentId,
      bot_id: botId,
      is_default: true,
    };

    axios
      .post<OrderBook[]>(`/api/order_books`, { order_book: formData })
      .then(() => {
        onAfterCreateDefault();
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      });
  }, [loadedOrderBooks]);
}
