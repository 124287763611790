import { createContext, useContext, useEffect, useRef, useState } from 'react';

import { ActiveOrder } from '../shared/interfaces/bot';
import { Channel } from 'phoenix';
import { BotsManagerContext } from './bots-manager';
import orderService from '../shared/order-service';
import socket from '../socket';

export interface ActiveOrderStore {
  activeOrders: ActiveOrder[];
}

const defaultState: ActiveOrderStore = {
  activeOrders: [],
};

export const ActiveOrderContext = createContext<ActiveOrderStore>(defaultState);

export function ActiveOrderContextProvider({ children }: any) {
  const { currentBot } = useContext(BotsManagerContext);

  let channel = useRef<Channel | null>(null);

  const [activeOrders, setActiveOrders] = useState<Array<ActiveOrder>>([]);

  useEffect(() => {
    if (!currentBot) return;

    channel.current = socket.channel(`active_order:${currentBot?.app_id}`, { from: 'ActiveOrderContextProvider' });

    channel.current
      ?.join()
      .receive('ok', () => {
        console.log(`[ActiveOrderContextProvider] Joined "active_order:${currentBot?.app_id}" channel for active orders real-time updates`);
      })
      .receive('error', (resp) => {
        console.log(
          `[ActiveOrderContextProvider] Cannot join "active_order:${currentBot?.app_id}" channel for active orders real-time updates`,
        );
      });

    channel.current?.onClose(() => {
      console.log(`[ActiveOrderContextProvider] Left "active_order:${currentBot?.app_id}" channel`);
    });

    // Process data from every run cycle of a pulser
    channel.current?.on('stream_processing:active_orders', ({ data }) => {
      const { orders } = data;
      setActiveOrders(orders);
      const workingOpportunityIds = orders.map((order: ActiveOrder) => order.opportunity.id);
      orderService.clearOpportunityExceedRateLimit(workingOpportunityIds);
    });

    return () => {
      channel.current?.leave();
    };
  }, [currentBot]);

  return <ActiveOrderContext.Provider value={{ activeOrders }}>{children}</ActiveOrderContext.Provider>;
}
