import { createContext, useState } from 'react';

export interface ScrollTargetPair {
  targetPairId: number | null;
  setTargetPairId(pair: number | null): void;
}

const defaultState: ScrollTargetPair = {
  targetPairId: null,
  setTargetPairId: () => {},
};

export const ScrollTargetPairContext = createContext<ScrollTargetPair>(defaultState);

export function ScrollTargetPairContextProvider({ children }: any) {
  const [targetPairId, setTargetPairId] = useState<number | null>(null);

  return <ScrollTargetPairContext.Provider value={{ targetPairId, setTargetPairId }}>{children}</ScrollTargetPairContext.Provider>;
}
