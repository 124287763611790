import { useCallback, useContext, useEffect, useState } from 'react';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import { Alignment, Button, Classes, Navbar, NavbarDivider, NavbarGroup, Icon, NavbarHeading, Dialog, Intent } from '@blueprintjs/core';
import axios from '../../shared/custom-axios';

import { ThemeMode, UserResponse } from '../../shared/interfaces/bot';
import { AppToaster } from '../../shared/app-toaster';
import { TradingPairOmnibar } from '../common/trading-pair-omnibar/trading-pair-omnibar';
import orderService from '../../shared/order-service';
import { matchPath } from 'react-router';
import { BotsManagerContext } from '../../contexts/bots-manager';
import { AuthContext } from '../../contexts/auth';
import { UnderContructionContext } from '../../contexts/under-construction';
import { ThemeContext } from '../../contexts/theme';

import { NavigationBotsMenu } from './bots-menu/bots-menu';
import { TradingPairsMenu } from './trading-pairs-menu/trading-pairs-menu';
import { NavigationUserMenu } from './user-menu/user-menu';
import { CancelAllButton } from '../cancel-all/button';
import { MarketsInfoMenu } from './markets-info-menu/markets-info-menu';

export interface Params {
  appId: string;
}

export default function Navigation() {
  let { appId } = useParams<keyof Params>();
  const { currentUser, setCurrentUser } = useContext(AuthContext);
  const { bots, currentBot, setCurrentBot } = useContext(BotsManagerContext);
  const { isUnderConstruction, setUnderConstruction } = useContext(UnderContructionContext);
  const { mode, setMode } = useContext(ThemeContext);

  let location = useLocation();

  const [newOrderFills, setNewOrderFills] = useState<number>(0);

  const handleNewOrderFill = useCallback(() => {
    const match = matchPath(location.pathname, '/trade-feed');

    if (!match) {
      setNewOrderFills(orderService.orderFills.length);
    }
  }, [location, setNewOrderFills]);

  useEffect(() => {
    orderService.hedgeEvent.on('primary:order_fill:init', handleNewOrderFill);

    return () => {
      orderService.hedgeEvent.off('primary:order_fill:init', handleNewOrderFill);
    };
  }, []);

  useEffect(() => {
    // Select current bot based on URL when reloading the page
    const bot = bots.find((bot) => bot.app_id === appId);

    bot && setCurrentBot && setCurrentBot(bot);
  }, [appId, bots, setCurrentBot]);

  const handleChangeThemeMode = (mode: ThemeMode) => {
    if (!currentUser) {
      return;
    }

    setMode && setMode(mode);

    axios
      .put<UserResponse>(`/api/users/${currentUser.id}/preferences`, { user_preferences: { theme: mode } })
      .then((response) => {
        const newData = response.data.data;
        setCurrentUser && setCurrentUser(newData);
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      });
  };

  return (
    <>
      <Navbar>
        <NavbarGroup align={Alignment.LEFT}>
          <NavbarHeading className="font-bold">
            <NavigationBotsMenu />
          </NavbarHeading>

          <CancelAllButton></CancelAllButton>

          {currentBot && <TradingPairsMenu bot={currentBot} />}
        </NavbarGroup>

        <NavbarGroup align={Alignment.RIGHT}>
          <TradingPairOmnibar />

          <NavbarDivider />

          <NavLink
            to={'/trade-feed'}
            className={({ isActive }) => `${isActive ? 'bp4-active' : ''} bp4-menu-item ${Classes.POPOVER_DISMISS}`}
          >
            <Icon icon="th-filtered" />
            <div style={{ position: 'relative' }}>
              Trade Feed
              {newOrderFills && newOrderFills > 0 ? <span className="badge-content -top-3.5 -right-5">{newOrderFills}</span> : null}
            </div>
          </NavLink>

          <NavLink
            to={'/accounts'}
            className={({ isActive }) => `${isActive ? 'bp4-active' : ''} bp4-menu-item ${Classes.POPOVER_DISMISS}`}
          >
            <Icon icon="key" />
            <div>Accounts</div>
          </NavLink>

          <li className={`bp4-menu-item ${Classes.POPOVER_DISMISS}`}>
            <Icon icon="exchange" />
            <MarketsInfoMenu />
          </li>

          {currentUser && (
            <>
              <NavbarDivider />

              {mode === ThemeMode.Light && (
                <Button
                  title="Switch to dark mode"
                  icon="moon"
                  onClick={() => {
                    handleChangeThemeMode(ThemeMode.Dark);
                  }}
                />
              )}

              {mode === ThemeMode.Dark && (
                <Button
                  title="Switch to light mode"
                  icon="moon"
                  onClick={() => {
                    handleChangeThemeMode(ThemeMode.Light);
                  }}
                />
              )}

              <NavbarDivider />

              <Button
                icon="notifications"
                onClick={() => {
                  setUnderConstruction && setUnderConstruction(true);
                }}
              />

              <NavbarDivider />

              <NavigationUserMenu />
            </>
          )}
        </NavbarGroup>
      </Navbar>

      <Dialog
        portalContainer={document.getElementById('app') || undefined}
        isOpen={isUnderConstruction}
        onClose={() => {
          setUnderConstruction && setUnderConstruction(false);
        }}
      >
        <div className={Classes.DIALOG_BODY}>
          <h3 className="text-1xl">
            <strong>Coming soon</strong>
          </h3>
          <p className="mt-5">This feature is under construction.</p>
        </div>

        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              onClick={() => {
                setUnderConstruction && setUnderConstruction(false);
              }}
            >
              Close
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
}
