import { Button, Classes, Dialog, HTMLSelect, Intent, Label } from '@blueprintjs/core';

import { Bot, BotSetting, InstrumentsListResponse } from '../../../shared/interfaces/bot';
import React, { useContext, useEffect, useState } from 'react';
import { copyToClipboard } from '../../../shared/utils';
import { TimezoneContext } from '../../../contexts/timezone';
import { BotSettingTable } from './table';
import axios from '../../../shared/custom-axios';
import { BotsManagerContext } from '../../../contexts/bots-manager';
import { AppToaster } from '../../../shared/app-toaster';
import { BotSettingForm } from './form';

interface BotSettingListResponse {
  entries: BotSetting[];
}

export function BotSettingList() {
  const { dateFormater, timeFormater } = useContext(TimezoneContext);
  const columns = React.useMemo(
    () => [
      {
        Header: 'Key',
        accessor: (setting: BotSetting, _rowIndex: number) => {
          return (
            <span className="cursor-pointer" onClick={() => copyToClipboard(setting.key)}>
              {setting.key}
            </span>
          );
        },
      },
      {
        Header: 'Value',
        accessor: (setting: BotSetting, _rowIndex: number) => {
          return (
            <span className="cursor-pointer" onClick={() => copyToClipboard(setting.value)}>
              {setting.value}
            </span>
          );
        },
      },
      {
        Header: 'Description',
        accessor: 'description',
      },
      {
        Header: 'Created At',
        accessor: (setting: BotSetting, _rowIndex: number) => {
          const dateTime = new Date(setting.created_at + 'Z');
          return dateFormater.format(dateTime);
        },
        minWidth: 100,
        maxWidth: 100,
      },
      {
        Header: 'Updated At',
        accessor: (setting: BotSetting, _rowIndex: number) => {
          const dateTime = new Date(setting.updated_at + 'Z');
          return dateFormater.format(dateTime);
        },
        minWidth: 100,
        maxWidth: 100,
      },
      {
        id: 'action',
        Header: '',
        accessor: (setting: BotSetting, _rowIndex: number) => {
          return (
            <div className="flex">
              <Button icon="edit" minimal={true} intent={Intent.PRIMARY} small={true} onClick={() => editSetting(setting)} />
              <Button
                icon="trash"
                minimal={true}
                intent={Intent.DANGER}
                small={true}
                onClick={() => handleDelete(setting)}
                className="ml-1"
              />
            </div>
          );
        },
      },
    ],
    [dateFormater, timeFormater],
  );

  const { bots, currentBot } = useContext(BotsManagerContext);
  const [itemToEdit, setItemToEdit] = useState<BotSetting>();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openCloneSettingDialog, setOpenCloneSettingDialog] = useState<boolean>(false);
  const [items, setItems] = useState<BotSetting[]>([]);
  const [selectedBot, setSelectedBot] = useState<Bot>();
  const [selectedDestinationBot, setSelectedDestinationBot] = useState<Bot>();

  const handleBotChange = (evt: any) => {
    const bot = bots.find((bot) => bot.id === Number(evt.target.value));
    setSelectedBot(bot);
  };

  // handle delete setting
  const handleDelete = (setting: BotSetting) => {
    axios
      .delete<InstrumentsListResponse>(`/api/bot-settings/${setting?.id}`)
      .then(() => {
        setItems((prevState) => {
          const newState = prevState.filter(({ id }) => id !== setting?.id);
          return newState;
        });

        const message = `Setting ${setting.key} deleted successfully!`;
        AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 2000 });
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      });
  };

  // handle add new setting and update existed settings
  const editSetting = (setting: BotSetting) => {
    setItemToEdit(setting);
    setOpenDialog(true);
  };

  const addNewSetting = () => {
    setOpenDialog(true);
  };

  const handleSubmit = (data: BotSetting) => {
    if (data.id) {
      handleUpdateSetting(data);
    } else {
      handleNewSetting(data);
    }
  };

  const handleNewSetting = (newSetting: BotSetting) => {
    axios
      .post<BotSetting>('/api/bot-settings', { setting: newSetting })
      .then(() => {
        if (selectedBot) {
          loadSetting(selectedBot.id);
        }
        const message = 'Setting added successfully!';
        AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 2000 });
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      })
      .finally(() => setOpenDialog(false));
  };

  const handleUpdateSetting = (setting: BotSetting) => {
    axios
      .put<BotSetting>(`/api/bot-settings/${setting.id}`, { setting: setting })
      .then(() => {
        loadSetting(setting.bot_id);
        const message = 'Setting updated successfully!';
        AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 2000 });
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      })
      .finally(() => setOpenDialog(false));
  };

  const handleClose = () => {
    setItemToEdit(undefined);
    setOpenDialog(false);
  };

  // handle clone setting from selected bot to a destination bot
  const handleCloneSettings = () => {
    setOpenCloneSettingDialog(true);
  };

  const handleCloseCloneSetting = () => {
    setOpenCloneSettingDialog(false);
  };

  const handleSelectDestinationBot = (evt: any) => {
    const bot = bots.find((bot) => bot.id === Number(evt.target.value));
    setSelectedDestinationBot(bot);
  };

  const cloneSettings = () => {
    axios
      .post<BotSetting>('/api/bot-settings/clone', { source_bot: selectedBot?.id, destination_bot: selectedDestinationBot?.id })
      .then(() => {
        const message = `All settings of ${selectedBot?.name} has been added to bot ${selectedDestinationBot?.name} successfully!`;
        AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 2000 });
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      })
      .finally(() => setOpenCloneSettingDialog(false));
  };

  // send request to load the settings of the given bot
  const loadSetting = (bot_id: number) => {
    axios.get<BotSettingListResponse>(`/api/bot-settings?bot_id=${bot_id}`).then((response) => {
      setItems(response.data.entries);
    });
  };

  useEffect(() => {
    if (selectedBot) {
      loadSetting(selectedBot.id);
    }
  }, [selectedBot]);

  useEffect(() => {
    if (currentBot) {
      setSelectedBot(currentBot);
    }
  }, [currentBot]);

  return (
    <>
      <div className="flex">
        <HTMLSelect name="bot" value={selectedBot?.id} onChange={handleBotChange}>
          <option>Choose a bot</option>
          {bots.map((bot) => (
            <option value={bot.id} key={`bot_${bot.id}`}>
              {bot.name}
            </option>
          ))}
        </HTMLSelect>
        <Button icon="add" intent={Intent.PRIMARY} onClick={addNewSetting} className="ml-1">
          New Settings
        </Button>

        {selectedBot && (
          <Button icon="move" intent={Intent.PRIMARY} onClick={handleCloneSettings} className="ml-1">
            Clone
          </Button>
        )}
      </div>
      {items && <BotSettingTable columns={columns} data={items} />}
      <Dialog
        portalContainer={document.getElementById('app') || undefined}
        title={itemToEdit ? 'Edit Setting' : 'New Setting'}
        transitionDuration={500}
        isOpen={openDialog}
        onClose={handleClose}
        style={{ width: 500 }}
      >
        <div className={Classes.DIALOG_BODY}>
          <BotSettingForm setting={itemToEdit} handleSubmit={handleSubmit} />
        </div>
      </Dialog>

      <Dialog
        portalContainer={document.getElementById('app') || undefined}
        title={'Clone Setting'}
        transitionDuration={500}
        isOpen={openCloneSettingDialog}
        onClose={handleCloseCloneSetting}
        style={{ width: 500 }}
      >
        <div className={Classes.DIALOG_BODY}>
          <Label>
            Destination bot:
            <HTMLSelect name="bot" onChange={handleSelectDestinationBot}>
              <option>Choose a bot</option>
              {bots.map((bot) => (
                <option value={bot.id} key={`bot_${bot.id}`}>
                  {bot.name}
                </option>
              ))}
            </HTMLSelect>
          </Label>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
            <Button onClick={handleCloseCloneSetting}>Close</Button>
            <Button intent="primary" className="ml-2" onClick={cloneSettings}>
              Clone
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
}
